import React, { cloneElement, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  ChipField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TopToolbar,
  useListContext,
  useDataProvider,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Pagination } from '../../components';
import { marginZeroStyles, WEEK_DAYS } from '../../constants';
import { formatDatetime, formatCurrency } from '../../utils';

const useStyles = makeStyles(theme => ({
  ...marginZeroStyles,
  fixedWidth300: {
    width: '300px',
    display: 'block',
  },
  fixedWidth150: {
    width: '150px',
    display: 'block',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  gap: {
    gap: theme.spacing(1),
  },
  dlHorizontal: {
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    '& dt': {
      fontWeight: 700,
    },
  },
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const DebitCampaignList = props => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [strategies, setStrategies] = useState([]);
  const [admins, setAdmins] = useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  useEffect(() => {
    dataProvider
      .getList('auto_debit_strategies?page=1&items_per_page=200', {
        filter: {},
        pagination: { page: 1, perPage: 300 },
        sort: {},
      })
      .then(({ data }) => setStrategies(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));

    dataProvider
      .getList('admins', {
        filter: {},
        pagination: { page: 1, perPage: 300 },
        sort: {},
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  const getStrategyName = id => {
    const strategy = strategies.find(strategy => strategy.id === id);
    return strategy ? strategy.title : null;
  };
  const getAdminName = id => {
    const admin = admins.find(admin => admin.id === id);
    return admin ? admin.username : null;
  };

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" sortable={false} />
        <TextField source="activation_time" sortable={false} />
        <FunctionField label="Debit strategy" render={record => getStrategyName(record.strategy_id)} />
        <ChipField source="audience_id" size="small" sortable={false} />
        <FunctionField
          label="Period"
          className={classes.fixedWidth150}
          render={record => {
            const monthDays = Array.isArray(record?.params?.month_days) ? record.params.month_days : [];
            const weekDays = Array.isArray(record?.params?.week_days) ? record.params.week_days : [];

            monthDays.sort((a, b) => {
              a = a < 0 ? a + 100 : a;
              b = b < 0 ? b + 100 : b;

              return Math.sign(a - b);
            });
            weekDays.sort();

            return (
              <>
                {monthDays.length > 0 && (
                  <>
                    <div>
                      <b>Days of month:</b>
                    </div>
                    <div>{monthDays.map(day => (day === '-1' ? 'Last' : day)).join(', ')}</div>
                  </>
                )}

                {weekDays.length > 0 && (
                  <>
                    <div>
                      <b>Days of week:</b>
                    </div>
                    <div>{weekDays.map(day => WEEK_DAYS[day - 1] || 'UN').join(', ')}</div>
                  </>
                )}

                {record.params?.one_time_allocations && (
                  <>
                    <div>
                      <b>One-time allocation:</b>
                    </div>
                    <div>{record?.params?.one_time_allocations[0]}</div>
                  </>
                )}
              </>
            );
          }}
        />
        <FunctionField
          label="Last processed at"
          source="last_processed_at"
          render={(record, key) => (record[key] ? formatDatetime(record[key]) : null)}
          sortable={false}
        />
        <FunctionField
          label="Amounts"
          render={record => {
            return (
              <Grid container spacing={0.5} className={classes.fixedWidth300}>
                <dl className={classes.dlHorizontal}>
                  <dt>Total count</dt>
                  <dd>{record.total_request_count}</dd>
                  <dt>Total amount</dt>
                  <dd>{formatCurrency(record.total_request_amount)}</dd>
                  <dt>Pending count</dt>
                  <dd>{record.pending_request_count}</dd>
                  <dt>Pending amount</dt>
                  <dd>{formatCurrency(record.pending_request_amount)}</dd>
                  <dt>Completed count</dt>
                  <dd>{record.completed_request_count}</dd>
                  <dt>Completed amount</dt>
                  <dd>{formatCurrency(record.completed_request_amount)}</dd>
                  <dt>Charge back count</dt>
                  <dd>{record.charge_back_request_count}</dd>
                  <dt>Charge back amount</dt>
                  <dd>{formatCurrency(record.charge_back_request_amount)}</dd>
                  <dt>Error count</dt>
                  <dd>{record.error_request_count}</dd>
                  <dt>Error amount</dt>
                  <dd>{formatCurrency(record.error_request_amount)}</dd>
                </dl>
              </Grid>
            );
          }}
        />
        <FunctionField
          label="Created/Updated by"
          render={record => {
            return (
              <Grid container spacing={0.5}>
                <Grid item xs={12}>
                  <span>{`Created by ${getAdminName(record.created_by_id)}`}</span>
                </Grid>
                <Grid item xs={12}>
                  <span>{`Updated by ${getAdminName(record.updated_by_id)}`}</span>
                </Grid>
              </Grid>
            );
          }}
        />
        <BooleanField source="is_enabled" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default DebitCampaignList;
