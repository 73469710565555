import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  TablePagination,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  ButtonGroup,
  Button,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Link,
  Typography,
} from '@material-ui/core';
import { red, green } from '@material-ui/core/colors';
import { formatDatetime, formatPhoneNumber, snakeToHuman } from '../../utils';
import { EditUserContactsDialog } from '../dialog/EditUserContactsDialog';
import { useHandbook } from '../../hooks';
import { CONTACT_VALIDATION_STATE_LIST } from '../../constants';
import { Call } from '../call/Call';
import { WhatsAppButton } from '../whats_app/WhatsAppButton';

const useStyles = makeStyles(theme => ({
  fullWidth: {
    width: '100%',
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  valid: {
    backgroundColor: green[300],
  },
  invalid: {
    backgroundColor: red[300],
  },
  inlineFlex: {
    display: 'inline-flex',
  },
}));

const getName = (id, list) => {
  if (!id) {
    return null;
  }

  return list.find(item => item.id === id || item.code === id)?.name ?? id;
};

export const UserContactsTable = ({ userId, refreshedAt, setRefreshedAt, onCall, hasSoldLoans }) => {
  const [filterSources, setFilterSources] = useState([]);
  const [value, setValue] = useState('');
  const [filterName, setFilterName] = useState('');
  const [contacts, setContacts] = useState([]);
  const [total, setTotal] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [itemData, setItemData] = useState();
  const [perPage, setPerPage] = useState(30);

  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { permissions = [] } = usePermissions();

  const { data: contactRelationships } = useHandbook('phone_relations');
  const { data: phoneTypes } = useHandbook('phone_types');
  const { data: phoneSources } = useHandbook('phone_sources');

  const canPhoneBookEdit = permissions.includes('CAN_PHONE_BOOK_EDIT');

  const handleDialog = (type, data) => {
    if (data !== undefined) {
      setItemData(data);
    }
    setDialogIsOpen(type);
  };

  useEffect(() => {
    setFilterSources(phoneSources.map(({ id }) => id));
  }, [phoneSources]);

  useEffect(() => {
    let filter = {
      'user.id': userId,
      source: filterSources,
    };

    if (value) {
      filter = { ...filter, value };
    }

    if (filterName) {
      filter = { ...filter, name: filterName };
    }

    dataProvider
      .getList('phone_books', {
        filter,
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(({ data, total }) => {
        setContacts(data);
        setTotal(total);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider, userId, filterSources, value, filterName, refreshedAt, page, perPage]);

  const getStatusStyle = status => {
    if (status === 'active') {
      return classes.valid;
    }
    if (status === 'inactive') {
      return classes.invalid;
    }
  };

  const getValidationStyle = status => {
    if (status === 'valid') {
      return classes.valid;
    }
    if (status === 'invalid') {
      return classes.invalid;
    }
  };

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  if (!Array.isArray(contacts)) return null;

  return (
    <div className={classes.fullWidth}>
      <Box p={2}>
        <FormGroup row>
          {phoneSources.map(({ id, name }) => (
            <FormControlLabel
              key={id}
              label={name}
              control={
                <Checkbox
                  checked={filterSources.indexOf(id) > -1}
                  onChange={() => {
                    if (filterSources.indexOf(id) > -1) {
                      setFilterSources(filterSources.filter(item => item !== id));
                    } else {
                      setFilterSources([...filterSources, id]);
                    }
                  }}
                />
              }
            />
          ))}
        </FormGroup>
      </Box>
      <Box p={2}>
        <TextField
          id="filter-phone-number"
          label="Value"
          variant="filled"
          size="small"
          value={value}
          onChange={e => setValue(e.target.value)}
        />

        <TextField
          id="filter-name"
          label="Name"
          variant="filled"
          size="small"
          className={classes.ml2}
          value={filterName}
          onChange={e => setFilterName(e.target.value)}
        />
      </Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Phone priority</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Relation</TableCell>
            <TableCell>Source</TableCell>
            <TableCell>Note</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Validation</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell>Updated at</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.category || '---'}</TableCell>
              <TableCell>
                {/^\d{10,11}$/.test(row.value) ? (
                  <Box className={classes.inlineFlex}>
                    <Call
                      inactive={row.status !== 'active' || row.validation_state === 'INVALID' || hasSoldLoans}
                      userId={userId}
                      userPhone={row.value}
                      onCall={onCall}>
                      {formatPhoneNumber(row.value)}
                    </Call>
                    <WhatsAppButton
                      contact={row}
                      phoneTypes={phoneTypes}
                      disabled={row.status !== 'active' || row.validation_state === 'INVALID' || hasSoldLoans}
                    />
                  </Box>
                ) : (
                  row.value
                )}
              </TableCell>
              <TableCell>{row.weight || '---'}</TableCell>
              <TableCell>{row.name?.trim() || '---'}</TableCell>
              <TableCell>{getName(row.type, phoneTypes) || '---'}</TableCell>
              <TableCell>{getName(row.relation, contactRelationships) || '---'}</TableCell>
              <TableCell>{getName(row.source, phoneSources) || '---'}</TableCell>
              <TableCell>
                {
                  <>
                    {row.note?.trim() || '---'}
                    <br />
                    {row.matched_user && (
                      <Typography variant="body2">
                        Go to client{' '}
                        <Link href={`#users/${row.matched_user}`} underline="none" target="_blank" rel="noreferrer">
                          {`#${row.matched_user}`}
                        </Link>
                      </Typography>
                    )}
                  </>
                }
              </TableCell>
              <TableCell className={getStatusStyle(row.status)}>{snakeToHuman(row.status)}</TableCell>
              <TableCell className={getValidationStyle(row.validation_state)}>
                {CONTACT_VALIDATION_STATE_LIST.find(({ code }) => code === row.validation_state)?.label || 'None'}
              </TableCell>
              <TableCell>
                {row.created_at && (
                  <>
                    {formatDatetime(row.created_at)}
                    <br />
                    {row.created_by_username ? `Created by ${row.created_by_username}` : 'Created by client'}
                  </>
                )}
              </TableCell>
              <TableCell>
                {row.updated_at && (
                  <>
                    {formatDatetime(row.updated_at)}
                    <br />
                    {row.updated_by_username ? `Updated by ${row.updated_by_username}` : 'Updated by client'}
                  </>
                )}
              </TableCell>
              <TableCell align="right">
                {canPhoneBookEdit ? (
                  <ButtonGroup size="small" variant="contained" color="primary">
                    <Button onClick={() => handleDialog(true, row)} color={'primary'}>
                      Edit
                    </Button>
                  </ButtonGroup>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
      {canPhoneBookEdit && itemData && dialogIsOpen ? (
        <EditUserContactsDialog
          isOpened={dialogIsOpen}
          data={itemData}
          onClose={() => {
            handleDialog(false);
          }}
          onSubmit={(category, value, name, type, note, relation, source, status, validation_state) => {
            const id = itemData.id;
            dataProvider
              .update('phone_books', {
                id,
                data: {
                  category,
                  value,
                  name,
                  type,
                  note: note?.trim() || null,
                  relation,
                  source,
                  status: status || 'active',
                  validation_state,
                },
              })
              .then(() => {
                setRefreshedAt(Date.now());
                handleDialog(false);
              })
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
        />
      ) : null}
    </div>
  );
};

UserContactsTable.propTypes = {
  userId: PropTypes.number,
  refreshedAt: PropTypes.number,
  setRefreshedAt: PropTypes.func,
  onCall: PropTypes.func,
  hasSoldLoans: PropTypes.bool,
};
