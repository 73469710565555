import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { Pagination, DateFilterInput } from '../../components';
import { formatDatetime, formatPhoneNumber, getSomeDaysAgo } from '../../utils';
import { marginZeroStyles } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  breakableCell: {
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  fixedWidth: {
    width: '125px',
    display: 'block',
  },
}));

const OutboundSmsListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <TextInput source="from_number" label="From" alwaysOn />
      <TextInput source="to_number" label="To" alwaysOn />
      <NumberInput label="Id" source="id" />
      <SelectInput
        label="Send mode"
        source="communication->mode"
        choices={[
          { id: 'automatic', name: 'Automatic' },
          { id: 'manual', name: 'Manual' },
        ]}
      />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
    </Filter>
  );
};

const OutboundSmsListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

OutboundSmsListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const OutboundSmsList = props => {
  const classes = useStyles();

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<OutboundSmsListFilter />}
      filter={{ 'communication.direction': 'outcome' }}
      filterDefaultValues={{ 'created_at|after': getSomeDaysAgo(3) }}
      actions={<OutboundSmsListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="communication.mode" label="Send mode" />
        <FunctionField
          label="From"
          render={row =>
            row.from_number && /^\d+$/.test(row.from_number)
              ? formatPhoneNumber(row.from_number)
              : row.from_number ?? '---'
          }
          className={classes.fixedWidth}
        />
        <FunctionField
          label="To"
          render={row =>
            row.to_number && /^\d+$/.test(row.to_number) ? formatPhoneNumber(row.to_number) : row.to_number ?? '---'
          }
          className={classes.fixedWidth}
        />
        <TextField source="text" className={classes.breakableCell} />
        <FunctionField label="State" render={row => <Chip size="small" label={row.communication.state} />} />
        <FunctionField
          label="Admin/Result/Category"
          render={row => (
            <>
              <div>
                <b>Admin:</b> {row.communication.admin?.username ?? '---'}
              </div>
              <div>
                <b>Result:</b> {row.communication.result?.name ?? '---'}
              </div>
              <div>
                <b>Category:</b> {row.communication.category?.name ?? '---'}
              </div>
            </>
          )}
        />
        <FunctionField label="Created at" render={row => formatDatetime(row.created_at)} />
      </Datagrid>
    </List>
  );
};

export default OutboundSmsList;
