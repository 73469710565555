import React from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'react-admin';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  green: {
    color: '#25d366',
  },
  grey: {
    color: theme.palette.grey[400],
  },
}));

export const WhatsAppButton = ({ contact, phoneTypes, disabled }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const whatsAppLink = `https://api.whatsapp.com/send?phone=52${contact.value}`;
  const phoneTypeId = phoneTypes.find(item => item.code === contact.type)?.id ?? null;

  const handleClick = () => {
    window.open(whatsAppLink, '_blank');
    if (phoneTypeId) {
      dataProvider
        .query(`users/${contact.user}/click_whatsapp`, {
          method: 'POST',
          body: JSON.stringify({
            phone_type_id: phoneTypeId,
            phone_number: contact.value,
          }),
        })
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
  };

  return (
    <IconButton onClick={handleClick} disabled={disabled}>
      <WhatsAppIcon className={disabled ? classes.grey : classes.green} />
    </IconButton>
  );
};

WhatsAppButton.propTypes = {
  contact: PropTypes.object,
  phoneTypes: PropTypes.array,
  disabled: PropTypes.bool,
};
