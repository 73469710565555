import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  FunctionField,
  List,
  NumberInput,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import Chip from '@material-ui/core/Chip';
import { LOAN_LIST_ROWS } from '../loan/constants';
import { CALL_CENTER_USER_STATUS_CLOSED_LOANS, REQUEST_TYPE_CHOICES } from '../../constants';
import CallCenterDropdownDialog from './components/CallCenterDropdownDialog';
import CallCenterLogDialog from './components/CallCenterLogDialog';
import { assignEssence, changeStatus } from './actions';
import { Pagination, DateFilterInput, QuickFilterInput, CallCenterLogTable, ListFilterHOC } from '../../components';
import { formatDatetime } from '../../utils';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { ListActions } from '../user/UserList';
import ListFilter from './components/ListFilter';

const ClosedListFilter = ({ stateFilterChoices, ...props }) => (
  <ListFilter {...props}>
    <NumberInput label="Id" source="id" alwaysOn />
    <NumberInput label="User Id" source="user->id" />
    <TextInput label="User name" source="search_by_name" />
    <TextInput label="Contract number" source="application->contractNumber" />
    <TextInput label="Document number" source="user->userDocuments->number" />
    <TextInput label="User contacts" source="user->phoneBooks->value" />
    {stateFilterChoices.length ? <SelectInput label="Status" source="state" choices={stateFilterChoices} /> : null}
    <NumberInput label="Min principal" source="principal|gte" />
    <NumberInput label="Max principal" source="principal|lte" />
    <NumberInput label="Min tenor" source="tenor|gte" />
    <NumberInput label="Max tenor" source="tenor|lte" />
    <DateFilterInput label="Created before" source="created_at|before" before />
    <DateFilterInput label="Created after" source="created_at|after" after />
    <DateFilterInput label="Closed before" source="closed_at|before" before />
    <DateFilterInput label="Closed after" source="closed_at|after" after />
    <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
    <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
    <DateFilterInput label="Matured before" source="matured_at|before" before />
    <DateFilterInput label="Matured after" source="matured_at|after" after />
    <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
    <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
    <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
    <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
    <SelectInput label="Loan type" source="is_repeat" choices={REQUEST_TYPE_CHOICES} />
    <QuickFilterInput source="is_direct_debit_registered" label="Direct debit registered" defaultValue={true} />
    <TextInput label="Residence municipality" source="user->residence->municipality" />
    <NumberInput label="Max days past due" source="days_past_due|lte" />
    <NumberInput label="Min days past due" source="days_past_due|gte" />
  </ListFilter>
);

ClosedListFilter.propTypes = {
  stateFilterChoices: PropTypes.array,
};

export const CallCenterListClosedLoans = ({ statefilterchoices = [], permissions, ...props }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [refreshedAt, setRefreshedAt] = useState(null);
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const [openDropdownDialog, setOpenDropdownDialog] = useState(false);
  const [loanIdLog, setLoanIdLog] = useState(0);
  const [loanIdStatus, setLoanIdStatus] = useState(0);

  const canEdit = permissions?.includes('CAN_PHONE_CALL_EDIT');

  return (
    <>
      <CallCenterLogDialog open={openLogDialog} close={setOpenLogDialog} title="Loan status log">
        <CallCenterLogTable
          refreshedAt={refreshedAt}
          filter={{ 'loan.id': loanIdLog, context: 'loan_closed_loans' }}
          isDialog
        />
      </CallCenterLogDialog>
      <CallCenterDropdownDialog
        title="Change loan status"
        isOpened={openDropdownDialog}
        onClose={() => setOpenDropdownDialog(state => !state)}
        onSubmit={userStatus =>
          changeStatus(userStatus, dataProvider, notify, setOpenDropdownDialog, refresh, 'phone_calls', {
            loan_id: loanIdStatus,
            result: userStatus,
            context: 'loan_closed_loans',
          })
        }
        selectOptions={CALL_CENTER_USER_STATUS_CLOSED_LOANS}
      />
      <List
        pagination={<Pagination />}
        bulkActionButtons={false}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<ClosedListFilter stateFilterChoices={statefilterchoices} />}
        actions={<ListActions />}
        permissions={permissions}
        {...props}>
        <Datagrid rowClick="show">
          {LOAN_LIST_ROWS.slice(0, 9).map(i => i)}
          <FunctionField label="Closing date" render={({ closed_at }) => formatDatetime(closed_at)} />
          <FunctionField
            onClick={e => e.stopPropagation()}
            label="Status"
            render={({ last_phone_calls_by_context, id }) => {
              if (last_phone_calls_by_context?.loan_closed_loans?.operator) {
                return (
                  <Box>
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdStatus(id);
                          setOpenDropdownDialog(state => !state);
                        }}
                        disabled={!canEdit}>
                        Change
                      </Button>
                    </Box>
                    <Box mt={1}>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setLoanIdLog(id);
                          setRefreshedAt(new Date().valueOf());
                          setOpenLogDialog(state => !state);
                        }}
                        disabled={permissions.indexOf('CAN_PHONE_CALL_VIEW') === -1}>
                        log
                      </Button>
                    </Box>
                  </Box>
                );
              } else {
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      assignEssence(dataProvider, notify, refresh, 'phone_call_loan_assigns', {
                        loan_id: id,
                        context: 'loan_closed_loans',
                      });
                    }}
                    disabled={!canEdit}>
                    Assign
                  </Button>
                );
              }
            }}
          />
          <FunctionField
            label="Operator"
            render={({ last_phone_calls_by_context }) => (
              <Chip label={last_phone_calls_by_context?.loan_closed_loans?.operator || 'none'} size="small" />
            )}
          />
          <FunctionField
            label="Last call result"
            render={({ last_phone_calls_by_context }) => (
              <Chip label={last_phone_calls_by_context?.loan_closed_loans?.result || 'none'} size="small" />
            )}
          />
          <FunctionField
            label="Last activity"
            render={({ last_phone_calls_by_context }) =>
              formatDatetime(last_phone_calls_by_context?.loan_closed_loans?.called_at)
            }
          />
          {LOAN_LIST_ROWS.slice(9).map(i => i)}
        </Datagrid>
      </List>
    </>
  );
};

CallCenterListClosedLoans.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
  permissions: PropTypes.arrayOf(PropTypes.string),
};

const CallCenterClosedLoansList = ListFilterHOC(CallCenterListClosedLoans, { closedLoans: true }, [], ClosedListFilter);

export default CallCenterClosedLoansList;
