import React from 'react';

const ImportProcessHelperSoldAmount = () => {
  return (
    <div>
      <p>
        Csv file,{' '}
        <a href="/admin/import_example/sold_amount.csv" download="import_example_sold_amount.csv">
          download example file
        </a>
        , columns definition:
      </p>
      <ul>
        <li>
          <b>loan_id</b>, required
        </li>
        <li>
          <b>amount</b>, required, use . (point) for fractional amounts
        </li>
      </ul>
    </div>
  );
};

export default ImportProcessHelperSoldAmount;
