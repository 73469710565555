import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify, useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  errorColor: {
    backgroundColor: 'red',
    textAlign: 'center',
    '& a': {
      color: 'white',
    },
  },
}));

const UserLink = ({ userId }) => {
  return <Link to={`/users/${userId}/show`}>{userId}</Link>;
};

UserLink.propTypes = {
  userId: PropTypes.string.isRequired,
};

export const SessionsTable = ({ userId, refreshedAt }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [sessions, setSessions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(30);

  useEffect(() => {
    const filter = { 'user.id': userId };
    dataProvider
      .getList('sessions', {
        filter,
        pagination: { page, perPage },
        sort: { field: 'id', order: 'DESC' },
      })
      .then(response => {
        setSessions(response.data);
        setLoading(false);
        setTotal(response.total);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [userId, dataProvider, refreshedAt, page, perPage]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }
  if (!Array.isArray(sessions)) return null;
  return (
    <div className={classes.fullWidth}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {['Id', 'User', 'Cookie Id', 'Remote address', 'User agent', 'Device', 'Created at'].map(i => (
              <TableCell key={i}>{i}</TableCell>
            ))}
            <TableCell align="center">Geolocation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sessions.map(row => {
            return (
              <TableRow key={`${row.id}-session-table`}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.user_id}</TableCell>
                <TableCell
                  className={
                    row?.evercookie_id !== null && +row?.evercookie_id !== +row?.user_id ? classes.errorColor : ''
                  }>
                  <UserLink userId={row.evercookie_id && row.evercookie_id} />
                </TableCell>
                <TableCell>{row.remote_address}</TableCell>
                <TableCell>
                  {`OS: ${row.user_agent_data?.os ?? '---'}`}
                  <br />
                  {`Device: ${row.user_agent_data?.device ?? '---'}`}
                  <br />
                  {`Browser: ${row.user_agent_data?.browser ?? '---'}`}
                </TableCell>
                <TableCell>
                  {`Fingerprint: ${row.device_fingerprint ?? 'N/D'}`}
                  <br />
                  {`Id: ${row.device?.device_id ?? 'N/D'}`}
                </TableCell>
                <TableCell>{formatDatetime(row.created_at)}</TableCell>
                <TableCell>
                  {row.geolocation && (
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell>Ip</TableCell>
                          <TableCell>{row.geolocation.ip}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>City</TableCell>
                          <TableCell>{row.geolocation.city}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Region</TableCell>
                          <TableCell>{row.geolocation.region}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Country</TableCell>
                          <TableCell>{row.geolocation.country}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Location</TableCell>
                          <TableCell>{row.geolocation.loc}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Postal</TableCell>
                          <TableCell>{row.geolocation.postal}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Timezone</TableCell>
                          <TableCell>{row.geolocation.timezone}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={total}
        rowsPerPage={perPage}
        page={page - 1}
        onPageChange={(e, page) => setPage(page + 1)}
        onRowsPerPageChange={e => {
          setPerPage(parseInt(e.target.value, 10));
          setPage(1);
        }}
      />
    </div>
  );
};

SessionsTable.propTypes = {
  userId: PropTypes.number.isRequired,
  refreshedAt: PropTypes.number,
};

SessionsTable.defaultProps = {
  userId: null,
  refreshedAt: 0,
};
