import React from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'react-admin';
import { DateTimePicker as MuiDateTimePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { timezone } from '../../utils';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
}));

export const DateTimePicker = ({ label, externalError, removeError, ...props }) => {
  const classes = useStyles();
  const {
    input: { value, onChange },
    isRequired,
    meta: { touched, error },
  } = useInput({
    ...props,
    validate: value => (props.required && !value ? 'Please fill in obligatory field' : undefined),
  });

  const showError = Boolean(touched && error) || Boolean(externalError);

  const handleChange = value => {
    onChange(value);
    removeError && removeError(props.source);
  };

  React.useEffect(() => {
    if (value) {
      onChange(timezone.shift(new Date(value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl className={classes.fullWidth} required={isRequired} error={showError}>
      <MuiDateTimePicker
        className={props.className || ''}
        clearable
        required={isRequired}
        error={showError}
        label={label}
        value={value ? new Date(value) : null}
        onChange={value => handleChange(value)}
        {...props}
      />
      {showError && <FormHelperText>{(touched && error) || externalError}</FormHelperText>}
    </FormControl>
  );
};

DateTimePicker.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  externalError: PropTypes.string,
  removeError: PropTypes.func,
  source: PropTypes.string,
};
