import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberField,
  NumberInput,
  TextInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, DateFilterInput, QuickFilterInput } from '../../components';
import { formatDatetime } from '../../utils';
import { marginZeroStyles, REQUEST_TYPE_CHOICES } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  const statusChoices = [
    { id: 'active', name: 'Active' },
    { id: 'closed', name: 'Closed' },
    { id: 'defaulted', name: 'Defaulted' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'pending', name: 'Pending' },
    { id: 'sold', name: 'Sold' },
    { id: 'cep_fail', name: 'CEP fail' },
    { id: 'transfer_fail', name: 'Transfer fail' },
  ];

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <SelectInput label="Status" source="state" choices={statusChoices} alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <TextInput label="User contacts" source="user->phoneBooks->value" />
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <SelectInput label="Loan type" source="is_repeat" choices={REQUEST_TYPE_CHOICES} />
      <QuickFilterInput source="is_direct_debit_registered" label="Direct debit registered" defaultValue={true} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
      <QuickFilterInput source="has_failed_money_transfer" label="Has failed money transfer" defaultValue={true} />
    </Filter>
  );
};

ListFilter.propTypes = {
  collectiongroups: PropTypes.array,
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const LoanListMoneyTransferStucked = props => {
  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      filter={{
        enum_state: 'active,closed,defaulted,inactive,pending,sold,cep_fail,transfer_fail',
        last_money_transfer_is_stuck: true,
      }}
      filterDefaultValues={{ state: 'pending' }}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField
          key="name"
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id} target="_blank">
              {`${user_first_name} ${user_last_name} #${user_id}`}
            </Link>
          )}
        />
        <ChipField size="small" source="state" sortable={false} />
        <NumberField source="principal" options={{ style: 'currency', currency: 'MXN' }} sortable={false} />
        <ChipField size="small" source="last_money_transfer_status" sortable={false} />
        <TextField source="last_money_transfer_bank_account_number" sortable={false} />
        <FunctionField
          source="last_money_transfer_created_at"
          render={({ last_money_transfer_created_at }) => formatDatetime(last_money_transfer_created_at)}
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default LoanListMoneyTransferStucked;
