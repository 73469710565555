import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useDataProvider, DashboardMenuItem, getResources, MenuItemLink } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BookIcon from '@material-ui/icons/Book';
import DefaultIcon from '@material-ui/icons/ViewList';
import SmsIcon from '@material-ui/icons/Sms';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Badge from '@material-ui/core/Badge';
import SettingsIcon from '@material-ui/icons/Settings';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import FolderIcon from '@material-ui/icons/Folder';
import NextWeekIcon from '@material-ui/icons/NextWeek';
import DraftsIcon from '@material-ui/icons/Drafts';
import StorageIcon from '@material-ui/icons/Storage';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CallIcon from '@material-ui/icons/Call';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import { Watermark } from '../watermark/Watermark';
import { getUserId } from '../../utils';

const useStyles = makeStyles(theme => ({
  listItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  listItemIcon: {
    minWidth: theme.spacing(3.75),
  },
  listItemText: {
    marginTop: 0,
    marginBottom: 0,
  },
  nested: {
    paddingLeft: theme.spacing(1.5),
  },
  pt: {
    paddingTop: theme.spacing(1.5),
  },
  menuItemLink: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    whiteSpace: 'normal',
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3.75),
    },
  },
  dashboardMenuItem: {
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    '& .MuiListItemIcon-root': {
      minWidth: theme.spacing(3.75),
    },
  },
  active: {
    color: theme.palette.primary.main,
  },
  menuItemLinkRoot: {
    maxWidth: '280px !important',
    whiteSpace: 'normal !important',
  },
}));

const MenuGroup = ({ id, label, icon, resources, onMenuClick, isSidebarOpen, children, counters = null }) => {
  const classes = useStyles();
  const [isOpened, setIsOpened] = useState(false);
  const items = resources.filter(item => item.options.menuGroup === id && !item.options.subMenuGroup);

  if (items.length === 0) {
    return null;
  }

  return (
    <>
      <ListItem className={classes.listItem} button onClick={() => setIsOpened(!isOpened)}>
        <ListItemIcon className={classes.listItemIcon}>{icon || <SettingsIcon />}</ListItemIcon>
        <ListItemText className={classes.listItemText} primary={label} />
        {isOpened ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={isOpened} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={isSidebarOpen ? classes.nested : null}>
          {items.map(resource => {
            const hasCounter = counters && resource.options.counter && counters[resource.options.counter];
            const label = (resource.options && resource.options.label) || resource.name;
            const className = [classes.menuItemLink];
            if (hasCounter) {
              className.push(classes.pt);
            }
            const primaryText = isSidebarOpen ? (
              hasCounter ? (
                <Badge badgeContent={counters[resource.options.counter]} color="error" overlap="rectangular">
                  {label}
                </Badge>
              ) : (
                label
              )
            ) : (
              label
            );
            return (
              <MenuItemLink
                className={className.join(' ')}
                key={resource.name}
                to={`/${resource.name}`}
                primaryText={primaryText}
                leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={isSidebarOpen}
                classes={{ root: classes.menuItemLinkRoot, active: classes.active }}
              />
            );
          })}
          {children}
        </List>
      </Collapse>
    </>
  );
};

MenuGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  resources: PropTypes.array,
  onMenuClick: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  children: PropTypes.node,
  counters: PropTypes.object,
};

export const Menu = ({ onMenuClick, logout }) => {
  const [counters, setCounters] = useState(null);

  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const currentRoute = useSelector(state => state.router.location.pathname);
  const resources = useSelector(getResources);
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const userId = getUserId();

  const getNewCounters = () => {
    dataProvider
      .query(`admin/menu_counters/${userId}`, { method: 'GET' })
      .then(({ data }) => setCounters(data))
      // eslint-disable-next-line
      .catch(error => console.error(error.message));
  };

  useEffect(() => {
    dataProvider
      .query(`admin/menu_counters/${userId}`, { method: 'GET' })
      .then(({ data }) => setCounters(data))
      // eslint-disable-next-line
      .catch(error => console.error(error.message));
  }, [dataProvider, userId]);

  useEffect(() => {
    const interval = setInterval(() => {
      getNewCounters();
    }, 300000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Watermark bottom="30%" left="30px" />
      <DashboardMenuItem
        className={
          currentRoute === '/' ? [classes.dashboardMenuItem, classes.active].join(' ') : classes.dashboardMenuItem
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <MenuGroup
        id="mails"
        label="Emails"
        icon={<DraftsIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />
      <MenuGroup
        id="sms"
        label="SMS"
        icon={<SmsIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />
      {resources.map(resource => {
        if (resource.options.menuGroup) {
          return null;
        }
        return (
          <MenuItemLink
            className={classes.menuItemLink}
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            classes={{ active: classes.active }}
          />
        );
      })}
      <MenuGroup
        id="users"
        label="Users"
        icon={<PeopleIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="applications"
        label="Applications"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
        counters={counters}
      />

      <MenuGroup
        id="call_centers"
        label="Call center"
        icon={<CallIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="loans"
        label="Loans"
        icon={<MonetizationOnIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
        counters={counters}>
        <MenuGroup
          id="pending"
          label="Pending"
          icon={<FolderIcon />}
          resources={resources}
          onMenuClick={onMenuClick}
          isSidebarOpen={open}
          counters={counters}
        />
      </MenuGroup>

      <MenuGroup
        id="marketing"
        label="Marketing"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="management"
        label="Management"
        icon={<FolderIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="collection"
        label="Collection"
        icon={<NextWeekIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="products"
        label="Products"
        icon={<StorageIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup
        id="handbooks"
        label="Handbooks"
        icon={<BookIcon />}
        resources={resources}
        onMenuClick={onMenuClick}
        isSidebarOpen={open}
      />

      <MenuGroup id="settings" label="Settings" resources={resources} onMenuClick={onMenuClick} isSidebarOpen={open}>
        <MenuGroup
          id="autocreating_apps"
          label="Autocreating apps"
          icon={<FolderIcon />}
          resources={resources}
          onMenuClick={onMenuClick}
          isSidebarOpen={open}
        />
      </MenuGroup>

      {isXSmall && logout}
    </>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  logout: PropTypes.object,
};
