import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ChipField,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  SelectInput,
  TextField,
  TopToolbar,
  useListContext,
  useNotify,
  useDataProvider,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { formatDatetime, formatCurrency } from '../../utils';
import { Pagination, DateFilterInput } from '../../components';
import { marginZeroStyles } from '../../constants';
import ExpandPanel from './ExpandPanel';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
  fixedWidth: {
    width: '200px',
    display: 'block',
  },
}));

const ListFilter = props => {
  const classes = useStyles();

  const stateChoices = [
    { id: 'error', name: 'Error' },
    { id: 'pending', name: 'Pending' },
    { id: 'processing', name: 'Processing' },
    { id: 'finished', name: 'Finished' },
  ];

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User ID" source="user" />
      <NumberInput label="Loan ID" source="loan" />
      <NumberInput label="Admin ID" source="admin" />
      <SelectInput label="State" source="state" choices={stateChoices} />
      <NumberInput label="Amount max" source="amount|lte" />
      <NumberInput label="Amount min" source="amount|gte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Confirmed before" source="confirmed_at|before" before />
      <DateFilterInput label="Confirmed after" source="confirmed_at|after" after />
    </Filter>
  );
};

ListFilter.propTypes = {
  collectiongroups: PropTypes.array,
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const DebitRequestsList = props => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const classes = useStyles();

  useEffect(() => {
    dataProvider
      .query('admins', { method: 'GET' })
      .then(({ data }) => {
        setAdmins(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <CircularProgress />;

  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid expand={<ExpandPanel />} isRowExpandable={row => row.state && row.state !== 'pending'}>
        <TextField source="id" />
        <FunctionField
          sortable={false}
          label="Loan"
          render={({ loan_id }) => {
            return (
              <Link
                onClick={e => e.stopPropagation()}
                href={`#loans/${loan_id}/show`}
                target="_blank"
                rel="noreferrer">{`#${loan_id}`}</Link>
            );
          }}
        />
        <FunctionField
          sortable={false}
          label="Client"
          render={({ user_id }) => {
            return (
              <Link
                onClick={e => e.stopPropagation()}
                href={'#users/' + user_id}
                target="_blank"
                rel="noreferrer">{`#${user_id}`}</Link>
            );
          }}
        />
        <FunctionField
          sortable={false}
          label="Admin"
          render={({ admin_id }) => admins.find(a => a.id === admin_id)?.username}
        />
        <TextField source="campaign_id" sortable={false} />
        <TextField source="strategy_id" sortable={false} />
        <ChipField size="small" source="state" sortable={false} />
        <FunctionField label="Amount" render={({ amount }) => formatCurrency(amount)} sortBy="amount" />
        <FunctionField render={({ created_at }) => formatDatetime(created_at)} label="Created at" sortBy="created_at" />
        <TextField source="dpd" label="DPD" sortable={false} />
        <FunctionField
          render={({ confirmed_at }) => formatDatetime(confirmed_at)}
          label="Confirmed at"
          sortable={false}
        />
        <FunctionField
          render={({ error }) => (
            <Box className={classes.fixedWidth}>
              <Typography variant="caption">{error}</Typography>
            </Box>
          )}
          label="Error"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};

export default DebitRequestsList;
