import React from 'react';
import {
  Create,
  SaveButton,
  Toolbar,
  FormWithRedirect,
  required,
  maxLength,
  SelectInput,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { RCS_TEMPLATE_TYPE_CHOICES } from '../../constants';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  m2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = props => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={4}>
              <Paper>
                <Box p={2} paddingBottom={0}>
                  <Typography variant="h6" gutterBottom={false}>
                    Create RCS-template
                  </Typography>
                  <Divider className={classes.m2} />
                  <Grid container fullWidth spacing={0}>
                    <Grid item xs={12}>
                      <TextInput validate={[required(), maxLength(255)]} helperText={false} source="name" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput validate={[required()]} helperText={false} source="code" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectInput
                        helperText={false}
                        source="type"
                        validate={[required()]}
                        choices={RCS_TEMPLATE_TYPE_CHOICES}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const RcsTemplateCreate = ({ ...props }) => (
  <Create component="div" {...props}>
    <FormWrapper redirect="list" />
  </Create>
);

export default RcsTemplateCreate;
