import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  SaveButton,
  FormWithRedirect,
  TextInput,
  NumberInput,
  Toolbar,
  required,
  maxLength,
  minValue,
  maxValue,
  useRedirect,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box, Paper, Divider, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const redirect = useRedirect();

  return (
    <FormWithRedirect
      save={({ name }, ...rest) => {
        save(...[{ name }, ...rest]);
      }}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
              <Paper>
                <Box p={2}>
                  <Typography variant="h6" gutterBottom={false}>
                    Edit product payment comission strategy
                  </Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container fullWidth spacing={2}>
                    <Grid item xs={12}>
                      <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberInput
                        min={0}
                        max={100}
                        label="Percent, %"
                        source="percent"
                        validate={[required(), minValue(0), maxValue(100)]}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberInput
                        min={1}
                        max={100}
                        label="Rounding to"
                        source="rounding_to"
                        validate={[required(), minValue(1), maxValue(100)]}
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <NumberInput
                        min={1}
                        max={100000}
                        source="min_value"
                        label="Minimal value"
                        validate={[required(), minValue(1), maxValue(100000)]}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Toolbar className={classes.toolbar}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<CloseIcon />}
                    onClick={() => redirect(formProps.basePath)}>
                    Cancel
                  </Button>
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Toolbar>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const ProductPaymentCommissionStrategiesEdit = props => (
  <Edit mutationMode="pessimistic" component="div" {...props}>
    <FormWrapper />
  </Edit>
);

export default ProductPaymentCommissionStrategiesEdit;
