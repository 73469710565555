export const formValidator = ({
  discount_principal_percent,
  discount_interest_percent,
  discount_commission_percent,
  discount_past_due_interest_percent,
}) => {
  const errors = {};
  if (
    discount_principal_percent === 0 &&
    discount_interest_percent === 0 &&
    discount_commission_percent === 0 &&
    discount_past_due_interest_percent === 0
  ) {
    errors.discount_principal_percent = 'Fields cannot have values 0 at the same time';
    errors.discount_interest_percent = 'Fields cannot have values 0 at the same time';
    errors.discount_commission_percent = 'Fields cannot have values 0 at the same time';
    errors.discount_past_due_interest_percent = 'Fields cannot have values 0 at the same time';
  }
  if (
    discount_principal_percent === 100 &&
    discount_interest_percent === 100 &&
    discount_commission_percent === 100 &&
    discount_past_due_interest_percent === 100
  ) {
    errors.discount_principal_percent = 'Fields cannot have values 100 at the same time';
    errors.discount_interest_percent = 'Fields cannot have values 100 at the same time';
    errors.discount_commission_percent = 'Fields cannot have values 100 at the same time';
    errors.discount_past_due_interest_percent = 'Fields cannot have values 100 at the same time';
  }
  return errors;
};

export const notificationsValidator = fields => {
  if (fields.every(element => typeof element === 'object')) {
    const hasDuplicate = fields.some(
      (item, index, arr) => arr.filter(obj => obj.transmitter_id === item.transmitter_id).length > 1,
    );
    return hasDuplicate ? 'Each setting must have unique transmitter' : undefined;
  }
};
