import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify, usePermissions } from 'react-admin';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Box from '@material-ui/core/Box';
import {
  AddNotificationDialog,
  AddNoteDialog,
  NotificationTable,
  NoteTable,
  AgreementTable,
  SelfieMatchesTable,
  IdCardMatchesTable,
  ChangeHistoryTable,
  MatchesTable,
  SessionsTable,
  ApplicationTable,
  AventusDecisionEngineDataTable,
  TabPanel,
  JuicyScore,
  Kyc,
  UserApiData,
  DebounceButton,
  AddUserApiDataDialog,
  VerificationCallsTable,
  AllCallsTable,
  ReportTable,
  WazzupMessagesTable,
  RelatedProjectApplicationTable,
} from '../../../components';

const AdditionalInfo = ({ record, refreshedAt, refresh }) => {
  const [isAddNotificationDialogOpened, setIsAddNotificationDialogOpened] = useState(false);
  const [isAddNoteDialogOpened, setIsAddNoteDialogOpened] = useState(false);
  const [isUserApiDataDialogOpened, setIsUserApiDataDialogOpened] = useState(false);
  const [matches, setMatches] = useState(0);
  const [selfieMatches, setSelfieMatches] = useState(0);
  const [idCardMatches, setIdCardMatches] = useState(0);
  const [counters, setCounters] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { permissions = [] } = usePermissions();

  useEffect(() => {
    if (record.id) {
      dataProvider
        .query(`applications/${record.id}/relation_counters`, { method: 'GET' })
        .then(({ data }) => setCounters(data))
        .catch(error => notify(`Error: ${error.message}`, 'error'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const canMatchesView = permissions.includes('CAN_MATCHES_VIEW');

    if (canMatchesView) {
      dataProvider
        .query(`applications/${record.id}/user-field-matches`, {})
        .then(({ data }) => {
          setMatches(data.length);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });

      dataProvider
        .getList('aws_rekognition_matches', {
          pagination: {},
          sort: {},
          filter: { user: record.user_id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setSelfieMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });

      dataProvider
        .getList('aws_rekognition_id_card_matches', {
          pagination: {},
          sort: {},
          filter: { user: record.user_id, 'similarity[gte]': 99 },
        })
        .then(({ total }) => {
          setIdCardMatches(total);
        })
        .catch(error => {
          notify(`Error: ${error.message}`, 'error');
        });
    }
  }, [permissions, dataProvider, record, notify]);

  const applyNotificationTransition = (notificationId, name, params = {}) => {
    dataProvider
      .query(`notifications/${notificationId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const applyAgreementTransition = (agreementId, name, params = {}) => {
    dataProvider
      .query(`agreements/${agreementId}/apply_transition`, {
        method: 'POST',
        body: JSON.stringify({ name, params }),
      })
      .then(() => refresh())
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  const getHistoryAventusRequest = () => {
    dataProvider
      .query(`related_project_data?user_id=${record.user_id}`, { method: 'GET' })
      .then(() => {
        notify('History requested successfully', 'success');
        setTimeout(() => {
          refresh();
        }, 1000);
      })
      .catch(error => notify(`Error: ${error.message}`, 'error'));
  };

  return (
    <>
      <TabPanel
        labels={[
          '✖',
          `Notifications|${counters?.notifications ?? 0}`,
          `WhatsApp messages|${counters?.whatsapp_messages ?? 0}`,
          `Notes|${counters?.notes ?? 0}`,
          `Agreements|${counters?.agreements ?? 0}`,
          'Changes',
          `Applications|${counters?.applications ?? 0}`,
          `Other project applications|${counters?.other_project_applications ?? 0}`,
          `Matches|${matches}`,
          `ADE Data|${counters?.ade_data ?? 0}`,
          `Sessions|${counters?.sessions ?? 0}`,
          'Juicy Score',
          `Selfie matches|${selfieMatches}`,
          `Id card matches|${idCardMatches}`,
          `KYC|${counters?.kyc_requests ?? 0}`,
          `Api data|${counters?.user_api_data ?? 0}`,
          `Verification calls|${counters?.verification_calls ?? 0}`,
          `All calls|${counters?.all_calls ?? 0}`,
          `Credit bureau|${counters?.credit_bureaus ?? 0}`,
        ]}
        activeTabIndex={+window.location.href.split('activeTab=')[1]}>
        <></>
        {permissions.includes('CAN_NOTIFICATION_VIEW') ? (
          <>
            <NotificationTable
              applicationId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyNotificationTransition}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                {permissions.includes('CAN_NOTIFICATION_EDIT') ? (
                  <Button onClick={() => setIsAddNotificationDialogOpened(true)}>Add</Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_WAZZUP_MESSAGES_VIEW') ? (
          <>
            <WazzupMessagesTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_NOTE_VIEW') ? (
          <>
            <NoteTable applicationId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                {permissions.includes('CAN_NOTE_EDIT') ? (
                  <Button onClick={() => setIsAddNoteDialogOpened(true)}>Add</Button>
                ) : null}
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_AGREEMENT_VIEW') ? (
          <>
            <AgreementTable
              applicationId={record.id}
              refreshedAt={refreshedAt}
              onTransition={applyAgreementTransition}
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_APPLICATION_VIEW') ? (
          <>
            <ChangeHistoryTable
              entityId={record.id}
              entityField="application"
              refreshedAt={refreshedAt}
              endpoint="application_change_histories"
            />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_APPLICATION_VIEW') ? (
          <>
            <ApplicationTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_RELATED_PROJECT_APPLICATIONS_VIEW') ? (
          <>
            <RelatedProjectApplicationTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={getHistoryAventusRequest}>GET HISTORY AVENTUS</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_MATCHES_VIEW') ? (
          <>
            <MatchesTable permissions={permissions} recordId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_APPLICATION_VIEW') ? (
          <>
            <AventusDecisionEngineDataTable recordId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_USER_VIEW') ? (
          <>
            <SessionsTable userId={record.user_id} refreshedAt={refreshedAt} returnSimilar={false} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_APPLICATION_VIEW') ? (
          <>
            <JuicyScore applicationId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_MATCHES_VIEW') ? (
          <>
            <SelfieMatchesTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_MATCHES_VIEW') ? (
          <>
            <IdCardMatchesTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_KYC_REQUEST_VIEW') ? (
          <>
            <Kyc userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_USER_API_DATA_VIEW') ? (
          <>
            <UserApiData userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
                {permissions.includes('CAN_USER_API_DATA_EDIT') && (
                  <Button onClick={() => setIsUserApiDataDialogOpened(true)}>Add</Button>
                )}
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_VERIFICATION_CALLS_VIEW') ? (
          <>
            <VerificationCallsTable applicationId={record.id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_WEBITEL_CALLS_VIEW') ? (
          <>
            <AllCallsTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
        {permissions.includes('CAN_CREDIT_BUREAU_REPORT_VIEW') ? (
          <>
            <ReportTable userId={record.user_id} refreshedAt={refreshedAt} />
            <Box p={1} display="flex" justifyContent="flex-end">
              <ButtonGroup variant="contained" size="small" color="primary">
                <DebounceButton onClick={refresh}>Refresh</DebounceButton>
              </ButtonGroup>
            </Box>
          </>
        ) : null}
      </TabPanel>
      {permissions.includes('CAN_NOTIFICATION_EDIT') && isAddNotificationDialogOpened ? (
        <AddNotificationDialog
          onClose={() => setIsAddNotificationDialogOpened(false)}
          onSubmit={(transmitterId, destination, templateId, message, subject, email_source) => {
            setIsAddNotificationDialogOpened(false);
            dataProvider
              .query(`applications/${record.id}/notifications`, {
                body: JSON.stringify({
                  transmitter_id: transmitterId,
                  destination,
                  template_id: templateId,
                  message,
                  subject,
                  channel: email_source,
                }),
              })
              .then(() => refresh())
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
          userId={record.user_id}
        />
      ) : null}
      {permissions.includes('CAN_NOTE_EDIT') ? (
        <AddNoteDialog
          isOpened={isAddNoteDialogOpened}
          onClose={() => setIsAddNoteDialogOpened(false)}
          onSubmit={(label, message) => {
            setIsAddNoteDialogOpened(false);
            dataProvider
              .create('notes', {
                data: {
                  user_id: record.user_id,
                  application_id: record.id,
                  label,
                  message,
                },
              })
              .then(() => refresh())
              .catch(error => notify(`Error: ${error.message}`, 'error'));
          }}
        />
      ) : null}
      {permissions.includes('CAN_USER_API_DATA_EDIT') && (
        <AddUserApiDataDialog
          userId={record.user_id}
          applicationId={record.id}
          isOpened={isUserApiDataDialogOpened}
          onClose={() => setIsUserApiDataDialogOpened(false)}
          onSubmit={() => {
            setIsUserApiDataDialogOpened(false);
            refresh();
          }}
        />
      )}
    </>
  );
};

AdditionalInfo.propTypes = {
  record: PropTypes.shape({
    user_id: PropTypes.number,
    id: PropTypes.number,
    user_phone_number: PropTypes.string,
    user_email_address: PropTypes.string,
  }),
  refreshedAt: PropTypes.number,
  refresh: PropTypes.func,
};

export default AdditionalInfo;
