import React from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { FileCopy } from '@material-ui/icons';
import placeholders from './placeholders.json';
import { copyToClipboard } from '../../utils';

const useStyles = makeStyles(theme => ({
  placeholderCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  btn: {
    padding: 0,
    '& .MuiSvgIcon-root': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
}));

export const PlaceholdersTable = () => {
  const notify = useNotify();
  const classes = useStyles();

  const copyPlaceholder = async text => {
    try {
      await copyToClipboard(text);
      notify(`Text ${text} copied to clipboard!`, { type: 'success' });
    } catch (error) {
      notify(`Error: ${error}`, { type: 'error' });
    }
  };
  return (
    <>
      <Typography variant="body1" gutterBottom={false}>
        All available placeholders
      </Typography>
      <Typography variant="caption" gutterBottom={false}>
        Click copy-icon to copy placeholder to clipboard
      </Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Placeholder</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {placeholders.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <Box className={classes.placeholderCell}>
                  <IconButton className={classes.btn} onClick={() => copyPlaceholder(row.placeholder)}>
                    <FileCopy />
                  </IconButton>
                  {row.placeholder}
                </Box>
              </TableCell>
              <TableCell>{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

PlaceholdersTable.propTypes = {
  source: PropTypes.string,
  locale: PropTypes.string,
};
