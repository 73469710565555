import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

export const LinearProgressField = ({ source, record = {} }) => (
  <Box display="flex" alignItems="center">
    <Box width="100%" mr={1}>
      <LinearProgress
        variant="determinate"
        value={record[source] <= 1 ? Math.round(100 * record[source]) : record[source]}
      />
    </Box>
    <Box minWidth={35}>
      <Typography
        variant="body2"
        color="textSecondary">{`${record[source] <= 1 ? Math.round(100 * record[source]) : record[source]}%`}</Typography>
    </Box>
  </Box>
);

LinearProgressField.propTypes = {
  source: PropTypes.string,
  record: PropTypes.object,
};
