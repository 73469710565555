import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  ListButton,
  SelectInput,
  TextInput,
  NumberInput,
  minValue,
  maxValue,
  number,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Autocomplete } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  TRANSMITTER,
  CAMPAIGN_TYPE_CHOICES,
  DISCOUNT_TYPE_CHOICES,
  DISCOUNT_EXTENSION_TYPE_CHOICES,
  DISCOUNT_CATEGORY_CHOICES,
  PAYMENT_LIMIT_CHOICES,
  AUDIENCE_TYPE_CHOICES,
  DAYS_MODE_DOW,
  DAYS_MODE_DOM,
  WEEK_DAYS,
  MONTH_DAYS,
  DAYS_MODE_ED,
} from '../../constants';
import { integerValidator, selectedValidator, shortTimeValidator } from '../../utils';
import { formValidator, notificationsValidator } from './validators';
import { checkAndSetDate } from './functions';
import { DateTimePicker } from './DateTimePicker';

const useStyles = makeStyles(theme => ({
  mb2: {
    marginBottom: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
}));

const FormWrapper = ({ save, record, ...props }) => {
  const [daysMode, setDaysMode] = useState(DAYS_MODE_DOW);
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [discountTypeSelected, setDiscountTypeSelected] = useState('');
  const [discountCategorySelected, setDiscountCategorySelected] = useState('');
  const [sendNotification, setSendNotification] = useState(false);
  const [errors, setErrors] = useState({});
  const [templateFilter, setTemplateFilter] = useState({ is_enabled: true });

  const classes = useStyles();

  useEffect(() => {
    setDaysMode(
      Array.isArray(record.params?.month_days) && record.params.month_days?.length > 0
        ? DAYS_MODE_DOM
        : record.params?.week_days?.length === 7
          ? DAYS_MODE_ED
          : DAYS_MODE_DOW,
    );
    setWeekDays(record.params?.week_days || []);
    setMonthDays(record.params?.month_days || []);
    setDiscountTypeSelected(record.discount_type);
    setSendNotification(record.send_notification);
    setDiscountCategorySelected(record.discount_category);
  }, [record]);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  const relevantTransmitters = TRANSMITTER.filter(transmitter => transmitter.id !== 'cm');

  return (
    <FormWithRedirect
      save={(
        {
          name,
          campaign_type,
          valid_days_count,
          valid_from,
          discount_category,
          discount_type,
          discount_total_amount,
          discount_total_percent,
          discount_principal_percent,
          discount_interest_percent,
          discount_commission_percent,
          discount_past_due_interest_percent,
          max_payments,
          audience_type,
          audience_id,
          file_id,
          is_enabled,
          show_to_client,
          send_notification,
          discount_campaign_notifications,
        },
        ...rest
      ) => {
        const validationErrors = {};
        if (record.campaign_type === 'regular' && daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (record.campaign_type === 'regular' && daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        const payload = {
          name,
          campaign_type,
          discount_category,
          discount_type,
          max_payments: discountCategorySelected === 'loan_extension' ? 1 : max_payments,
          audience_type,
          is_enabled,
          show_to_client,
          send_notification,
        };
        if (discount_type === 'amount') {
          payload.discount_total_amount = discount_total_amount ?? 0;
        }
        if (discount_type === 'total_percent') {
          payload.discount_total_percent = discount_total_percent ?? 0;
        }
        if (discount_type === 'compound_percent') {
          payload.discount_principal_percent = discount_principal_percent ?? 0;
          payload.discount_interest_percent = discount_interest_percent ?? 0;
          payload.discount_commission_percent = discount_commission_percent ?? 0;
          payload.discount_past_due_interest_percent = discount_past_due_interest_percent ?? 0;
        }
        if (audience_type === 'audience') {
          payload.audience_id = audience_id;
        }
        if (audience_type === 'file') {
          payload.file_id = file_id;
        }
        if (send_notification) {
          payload.discount_campaign_notifications = discount_campaign_notifications;
        } else {
          payload.discount_campaign_notifications = null;
        }
        if (record.campaign_type === 'regular') {
          payload.valid_from = checkAndSetDate(valid_from);
          payload.valid_days_count = valid_days_count;
          payload.params = {
            week_days: daysMode === DAYS_MODE_DOW || daysMode === DAYS_MODE_ED ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
          };
        }
        if (record.campaign_type === 'manual') {
          payload.valid_from = checkAndSetDate(valid_from);
          payload.valid_days_count = valid_days_count;
        }
        save(...[payload, ...rest]);
      }}
      validate={formValidator}
      {...props}
      render={formProps => (
        <form>
          <Grid container justifyContent="center" spacing={4}>
            <Grid item xs={12} sm={10}>
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom={false}>
                      {`Update ${record.campaign_type} discount campaign`}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider className={classes.my2} />
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        source="name"
                        validate={[required('Please fill in obligatory field'), maxLength(255)]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="campaign_type"
                        label="Type of campaign"
                        choices={CAMPAIGN_TYPE_CHOICES}
                        validate={[required('Please fill in obligatory field')]}
                        fullWidth
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    {record.campaign_type === 'regular' && (
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          label="Offer valid from"
                          source="valid_from"
                          inputVariant="filled"
                          fullWidth
                          margin="dense"
                          required
                          ampm={false}
                        />
                      </Grid>
                    )}
                    {record.campaign_type === 'manual' && (
                      <Grid item xs={12} sm={6}>
                        <DateTimePicker
                          label="Offer valid from"
                          source="valid_from"
                          inputVariant="filled"
                          fullWidth
                          margin="dense"
                          required
                          ampm={false}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <NumberInput
                        label="Offer valid till (shift in days from generation of offer)"
                        source="valid_days_count"
                        min={1}
                        max={100}
                        step={1}
                        fullWidth
                        validate={[
                          required('Please fill in obligatory field'),
                          minValue(1),
                          maxValue(100),
                          integerValidator('Incorrect discount'),
                          number('Incorrect discount'),
                        ]}
                      />
                    </Grid>
                    {record.campaign_type === 'regular' && (
                      <Grid item xs={12}>
                        <Typography variant="body1" color="textSecondary" gutterBottom={false}>
                          Periodicity
                        </Typography>
                        <RadioGroup row className={classes.mb2}>
                          <FormControlLabel
                            value={DAYS_MODE_DOW}
                            control={<Radio />}
                            label="Days of week"
                            checked={daysMode === DAYS_MODE_DOW}
                            onChange={() => {
                              setMonthDays([]);
                              setWeekDays([]);
                              setDaysMode(DAYS_MODE_DOW);
                              setErrors({});
                            }}
                          />
                          <FormControlLabel
                            value={DAYS_MODE_DOM}
                            control={<Radio />}
                            label="Days of month"
                            checked={daysMode === DAYS_MODE_DOM}
                            onChange={() => {
                              setMonthDays([]);
                              setWeekDays([]);
                              setDaysMode(DAYS_MODE_DOM);
                              setErrors({});
                            }}
                          />
                          <FormControlLabel
                            value={DAYS_MODE_ED}
                            control={<Radio />}
                            label="Every day"
                            checked={daysMode === DAYS_MODE_ED}
                            onChange={() => {
                              setMonthDays([]);
                              setWeekDays([1, 2, 3, 4, 5, 6, 7]);
                              setDaysMode(DAYS_MODE_ED);
                              setErrors({});
                            }}
                          />
                        </RadioGroup>

                        {daysMode === DAYS_MODE_DOW && (
                          <>
                            <div>
                              {WEEK_DAYS.map((day, idx) => (
                                <FormControlLabel
                                  key={day}
                                  control={
                                    <Checkbox
                                      onChange={() => onChangeWeekDay(idx + 1)}
                                      checked={weekDays.includes(idx + 1)}
                                    />
                                  }
                                  label={day}
                                />
                              ))}
                            </div>
                            <div>
                              {errors.week_days && (
                                <Typography color="error" variant="caption">
                                  {errors.week_days}
                                </Typography>
                              )}
                            </div>
                          </>
                        )}

                        {daysMode === DAYS_MODE_DOM && (
                          <>
                            <div className={classes.domList}>
                              {MONTH_DAYS.map(({ value, label }) => (
                                <div key={value} className={classes.domItem}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        onChange={() => onChangeMonthDay(value)}
                                        checked={monthDays.includes(value)}
                                      />
                                    }
                                    label={label}
                                  />
                                </div>
                              ))}
                            </div>
                            <div>
                              {errors.month_days && (
                                <Typography color="error" variant="caption">
                                  {errors.month_days}
                                </Typography>
                              )}
                            </div>
                          </>
                        )}
                      </Grid>
                    )}
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="discount_category"
                        label="Category of discount"
                        choices={DISCOUNT_CATEGORY_CHOICES}
                        validate={[required('Please fill in obligatory field')]}
                        fullWidth
                        onChange={e => {
                          setDiscountCategorySelected(e.target.value);
                          setDiscountTypeSelected('');
                          formProps.form.change('discount_type', null);
                          formProps.form.change('discount_total_amount', null);
                          formProps.form.change('discount_total_percent', null);
                          formProps.form.change('discount_principal_percent', null);
                          formProps.form.change('discount_interest_percent', null);
                          formProps.form.change('discount_commission_percent', null);
                          formProps.form.change('discount_past_due_interest_percent', null);
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="discount_type"
                        label="Type of discounts"
                        choices={
                          discountCategorySelected === 'loan_extension'
                            ? DISCOUNT_EXTENSION_TYPE_CHOICES
                            : DISCOUNT_TYPE_CHOICES
                        }
                        validate={[required('Please fill in obligatory field')]}
                        fullWidth
                        onChange={e => {
                          setDiscountTypeSelected(e.target.value);
                          formProps.form.change('discount_total_amount', null);
                          formProps.form.change('discount_total_percent', null);
                          formProps.form.change('discount_principal_percent', null);
                          formProps.form.change('discount_interest_percent', null);
                          formProps.form.change('discount_commission_percent', null);
                          formProps.form.change('discount_past_due_interest_percent', null);
                        }}
                      />
                    </Grid>
                    {discountTypeSelected === 'amount' && (
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          label="Discount MXN"
                          source="discount_total_amount"
                          fullWidth
                          min={1}
                          step={1}
                          validate={[
                            required('Please fill in obligatory field'),
                            minValue(1),
                            integerValidator('Incorrect discount'),
                            number('Incorrect discount'),
                          ]}
                        />
                      </Grid>
                    )}
                    {discountTypeSelected === 'total_percent' && (
                      <Grid item xs={12} sm={6}>
                        <NumberInput
                          label="Discount %"
                          source="discount_total_percent"
                          min={1}
                          max={99}
                          step={1}
                          fullWidth
                          validate={[
                            required('Please fill in obligatory field'),
                            minValue(1),
                            maxValue(99),
                            integerValidator('Incorrect discount'),
                            number('Incorrect discount'),
                          ]}
                        />
                      </Grid>
                    )}
                    {discountTypeSelected === 'compound_percent' && (
                      <Grid container spacing={2}>
                        {discountCategorySelected !== 'loan_extension' && (
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for Principal %"
                              source="discount_principal_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for Interest  %"
                            source="discount_interest_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for commission %"
                            source="discount_commission_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount for Past due interest %"
                            source="discount_past_due_interest_percent"
                            fullWidth
                            min={0}
                            max={100}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(0),
                              maxValue(100),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {discountCategorySelected !== 'loan_extension' && (
                    <>
                      <Divider className={classes.my2} />
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <SelectInput
                            source="max_payments"
                            label="Number of payments"
                            allowEmpty
                            emptyValue={0}
                            emptyText="Not selected"
                            optionText="name"
                            choices={PAYMENT_LIMIT_CHOICES}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SelectInput
                        source="audience_type"
                        label="Loans"
                        allowEmpty
                        emptyValue={1}
                        emptyText="Not selected"
                        choices={AUDIENCE_TYPE_CHOICES}
                        validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        resource="mass_sending_audiences"
                        label="Choose audience"
                        source="audience_id"
                        optionValueProp="id"
                        optionLabelProp="name"
                        required
                      />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <BooleanInput label="Active" disabled helperText={false} source="is_enabled" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <BooleanInput label="Display in private cabinet" helperText={false} source="show_to_client" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <BooleanInput
                        label="Send notification"
                        helperText={false}
                        source="send_notification"
                        onChange={value => {
                          setSendNotification(value);
                          !value && formProps.form.change('discount_campaign_notifications', undefined);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {sendNotification && (
                    <>
                      <Divider className={classes.my2} />
                      <ArrayInput
                        source="discount_campaign_notifications"
                        label="Notifications settings"
                        validate={[
                          required('Please add at least one row of fields'),
                          fields => notificationsValidator(fields),
                        ]}>
                        <SimpleFormIterator disableReordering>
                          <TextInput source="id" label="Id" helperText={false} fullWidth disabled />
                          <SelectInput
                            onChange={e => {
                              const value = e.target.value;
                              const fieldNamePrefix = e.target.name.split('.')[0];
                              formProps.form.change(`${fieldNamePrefix}.template_id`, null);
                              formProps.form.resetFieldState(`${fieldNamePrefix}.template_id`);
                              if (value === 'mailer') {
                                setTemplateFilter(prev => ({ ...prev, category: 'Email' }));
                              } else {
                                setTemplateFilter(prev => ({ ...prev, category: 'SMS' }));
                              }
                            }}
                            source="transmitter_id"
                            optionText="name"
                            label="Transmitter"
                            choices={relevantTransmitters}
                            validate={[required('Please fill in obligatory field')]}
                            fullWidth
                            helperText={false}
                          />
                          <Autocomplete
                            resource="templates"
                            source="template_id"
                            label="Template"
                            optionValueProp="id"
                            optionLabelProp="key"
                            filter={templateFilter}
                            required
                            requiredMessage="Please fill in obligatory field"
                          />
                          <TextInput
                            helperText="Format: hh:mm"
                            label="Sending time from"
                            source="notify_from"
                            validate={[required('Please fill in obligatory field'), value => shortTimeValidator(value)]}
                            fullWidth
                          />
                          <TextInput
                            helperText="Format: hh:mm"
                            label="Sending time till"
                            source="notify_to"
                            validate={[required('Please fill in obligatory field'), value => shortTimeValidator(value)]}
                            fullWidth
                          />
                        </SimpleFormIterator>
                      </ArrayInput>
                    </>
                  )}
                </Box>
                <Divider className={classes.my2} />
                <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
                  <ListButton icon={<ArrowBackIcon />} size="medium" label="Back to list" variant="outlined" />
                  <SaveButton
                    label="Save"
                    redirect="list"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  record: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    campaign_type: PropTypes.string,
    discount_type: PropTypes.string,
    max_payments: PropTypes.number,
    valid_from: PropTypes.string,
    is_enabled: PropTypes.bool,
    send_notification: PropTypes.bool,
    show_to_client: PropTypes.bool,
    audience_type: PropTypes.string,
    discount_category: PropTypes.string,
    params: PropTypes.shape({
      week_days: PropTypes.array,
      month_days: PropTypes.array,
    }),
    transmitter_id: PropTypes.number,
    discount_campaign_notifications: PropTypes.array,
  }),
};

const DiscountCampaignEdit = props => (
  <Edit component="div" actions={false} mutationMode="pessimistic" {...props}>
    <FormWrapper />
  </Edit>
);

export default DiscountCampaignEdit;
