import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns-tz';
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  ListButton,
  SelectInput,
  TextInput,
  NumberInput,
  useDataProvider,
  minValue,
  maxValue,
  number,
  useNotify,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { Autocomplete } from '../../components';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckFileResultsDialog from './CheckFileResultsDialog';
import {
  TRANSMITTER,
  CAMPAIGN_TYPE_CHOICES,
  DISCOUNT_TYPE_CHOICES,
  DISCOUNT_EXTENSION_TYPE_CHOICES,
  DISCOUNT_CATEGORY_CHOICES,
  PAYMENT_LIMIT_CHOICES,
  AUDIENCE_TYPE_CHOICES,
  DAYS_MODE_DOW,
  DAYS_MODE_DOM,
  WEEK_DAYS,
  MONTH_DAYS,
  DAYS_MODE_ED,
} from '../../constants';
import {
  integerValidator,
  selectedValidator,
  shortTimeValidator,
  getUserAttachments,
  getAdminTimezone,
} from '../../utils';
import { formValidator, notificationsValidator } from './validators';
import { checkAndSetDate } from './functions';
import { DateTimePicker } from './DateTimePicker';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  domList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat',
  },
  domItem: {
    flexBasis: '80px',
    flexShrink: 0,
  },
  hidden: {
    display: 'none',
  },
  removeIcon: {
    padding: 0,
    margin: 0,
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '5px',
  },
}));

const FormWrapper = ({ save, campaignType, ...props }) => {
  const [daysMode, setDaysMode] = useState(DAYS_MODE_DOW);
  const [weekDays, setWeekDays] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [discountTypeSelected, setDiscountTypeSelected] = useState('');
  const [discountCategorySelected, setDiscountCategorySelected] = useState('');
  const [audienceTypeSelected, setAudienceTypeSelected] = useState('');
  const [sendNotification, setSendNotification] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingFile, setLoadingFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [isCheckFileResultsDialogOpen, setIsCheckFileResultsDialogOpen] = useState(false);
  const [nonexistentLoans, setNonexistentLoans] = useState([]);
  const [excludedLoans, setExcludedLoans] = useState([]);
  const [templateFilter, setTemplateFilter] = useState({ is_enabled: true });

  const dataProvider = useDataProvider();
  const classes = useStyles();
  const notify = useNotify();
  const attachments = getUserAttachments(files);

  const onChangeWeekDay = id => {
    const element = weekDays.includes(id);
    if (element) {
      setWeekDays(weekDays.filter(i => i !== id));
    } else {
      setWeekDays([...weekDays, id]);
    }
  };

  const onChangeMonthDay = id => {
    const element = monthDays.includes(id);
    if (element) {
      setMonthDays(monthDays.filter(i => i !== id));
    } else {
      setMonthDays([...monthDays, id]);
    }
  };

  const removeError = (...fields) => {
    setErrors(Object.fromEntries(Object.entries(errors).filter(([name]) => fields.includes(name) === false)));
  };

  const handleAttachFile = async e => {
    try {
      if (e.target.files[0]?.size > 10240000) {
        return notify('Maximum file size is 10 Mb', 'error');
      }
      setLoadingFile(true);
      const formData = new FormData();
      formData.append('file', e.target.files[0], 'file');
      const { data } = await dataProvider.query('files/users', { method: 'POST', body: formData });
      const fileCheck = await dataProvider.query(
        `discount_campaign/file/${data.id}?category=${discountCategorySelected}`,
        { method: 'PATCH' },
      );
      if (fileCheck.data.nonexistent.length || fileCheck.data.excluded.length) {
        fileCheck.data.nonexistent.length > 0 && setNonexistentLoans(fileCheck.data.nonexistent);
        fileCheck.data.excluded.length > 0 && setExcludedLoans(fileCheck.data.excluded);
        setIsCheckFileResultsDialogOpen(true);
      }
      setFiles(prevState => [...prevState, data]);
    } catch (error) {
      notify(`Error: ${error.message}`, 'error');
    } finally {
      setLoadingFile(false);
      errors.file && removeError('file');
    }
  };
  const handleUnattachFile = id => {
    setExcludedLoans([]);
    setNonexistentLoans([]);
    setFiles(prevState => [...prevState.filter(i => i.id !== id)]);
  };

  const relevantTransmitters = TRANSMITTER.filter(transmitter => transmitter.id !== 'cm');

  return (
    <FormWithRedirect
      save={(
        {
          name,
          campaign_type,
          valid_days_count,
          valid_from,
          valid_to,
          discount_category,
          discount_type,
          discount_total_amount,
          discount_total_percent,
          discount_principal_percent,
          discount_interest_percent,
          discount_commission_percent,
          discount_past_due_interest_percent,
          max_payments,
          audience_type,
          audience_id,
          is_enabled,
          show_to_client,
          send_notification,
          discount_campaign_notifications,
        },
        ...rest
      ) => {
        const validationErrors = {};
        if (campaignType === 'regular' && daysMode === DAYS_MODE_DOW && weekDays.length === 0) {
          validationErrors.week_days = 'Week days should not be empty';
        }
        if (campaignType === 'regular' && daysMode === DAYS_MODE_DOM && monthDays.length === 0) {
          validationErrors.month_days = 'Month days should not be empty';
        }
        if (audience_type === 'file' && files.length === 0) {
          validationErrors.file = 'Upload a file!';
        }

        if (campaignType === 'onetime') {
          const validFromDate = new Date(valid_from);
          const validToDate = new Date(valid_to);
          if (validFromDate >= validToDate) {
            validationErrors.valid_to = '"Valid to" date and time must be after "Valid from" date and time';
          }
        }

        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
        const payload = {
          name,
          campaign_type,
          discount_category,
          discount_type,
          max_payments: discountCategorySelected === 'loan_extension' ? 1 : max_payments,
          audience_type,
          is_enabled,
          show_to_client,
          send_notification,
        };
        const currentTimeZone = getAdminTimezone(false);

        if (discount_type === 'amount') {
          payload.discount_total_amount = discount_total_amount;
        }
        if (discount_type === 'total_percent') {
          payload.discount_total_percent = discount_total_percent;
        }
        if (discount_type === 'compound_percent') {
          payload.discount_principal_percent = discount_category === 'loan_extension' ? 0 : discount_principal_percent;
          payload.discount_interest_percent = discount_interest_percent;
          payload.discount_commission_percent = discount_commission_percent;
          payload.discount_past_due_interest_percent = discount_past_due_interest_percent;
        }
        if (audience_type === 'audience') {
          payload.audience_id = audience_id;
        }
        if (audience_type === 'file') {
          payload.file_id = files[0].id;
        }
        if (send_notification) {
          payload.discount_campaign_notifications = discount_campaign_notifications;
        } else {
          payload.discount_campaign_notifications = null;
        }
        if (campaignType === 'onetime') {
          const formattedWithTimeZone = format(valid_to, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: currentTimeZone });
          payload.valid_to = formattedWithTimeZone;
        }
        if (campaignType === 'regular') {
          payload.valid_days_count = valid_days_count;
          payload.params = {
            week_days: daysMode === DAYS_MODE_DOW || daysMode === DAYS_MODE_ED ? weekDays : [],
            month_days: daysMode === DAYS_MODE_DOM ? monthDays : [],
          };
        }
        if (campaignType === 'manual') {
          payload.valid_days_count = valid_days_count;
        }
        payload.valid_from = checkAndSetDate(valid_from);

        save(...[payload, ...rest]);
      }}
      validate={formValidator}
      {...props}
      initialValues={{
        max_payments: 0,
        audience_type: campaignType === 'onetime' ? 1 : 'audience',
        campaign_type: campaignType,
        is_enabled: true,
        show_to_client: false,
        send_notification: false,
      }}
      render={formProps => {
        return (
          <form>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} sm={10}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Typography variant="h6" gutterBottom={false}>
                        {`Create ${campaignType} discount campaign`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          source="name"
                          validate={[required('Please fill in obligatory field'), maxLength(255)]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="campaign_type"
                          label="Type of campaign"
                          choices={CAMPAIGN_TYPE_CHOICES}
                          validate={[required('Please fill in obligatory field')]}
                          fullWidth
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      {(campaignType === 'regular' || campaignType === 'manual') && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <DateTimePicker
                              label="Offer valid from"
                              source="valid_from"
                              inputVariant="filled"
                              fullWidth
                              margin="dense"
                              required
                              ampm={false}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Offer valid till (shift in days from generation of offer)"
                              source="valid_days_count"
                              min={1}
                              max={100}
                              step={1}
                              fullWidth
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(1),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          {campaignType !== 'manual' && (
                            <Grid item xs={12}>
                              <Typography variant="body1" color="textSecondary" gutterBottom={false}>
                                Periodicity
                              </Typography>
                              <RadioGroup row className={classes.mb2}>
                                <FormControlLabel
                                  value={DAYS_MODE_DOW}
                                  control={<Radio />}
                                  label="Days of week"
                                  checked={daysMode === DAYS_MODE_DOW}
                                  onChange={() => {
                                    setMonthDays([]);
                                    setWeekDays([]);
                                    setDaysMode(DAYS_MODE_DOW);
                                    setErrors({});
                                  }}
                                />
                                <FormControlLabel
                                  value={DAYS_MODE_DOM}
                                  control={<Radio />}
                                  label="Days of month"
                                  checked={daysMode === DAYS_MODE_DOM}
                                  onChange={() => {
                                    setMonthDays([]);
                                    setWeekDays([]);
                                    setDaysMode(DAYS_MODE_DOM);
                                    setErrors({});
                                  }}
                                />
                                <FormControlLabel
                                  value={DAYS_MODE_ED}
                                  control={<Radio />}
                                  label="Every day"
                                  checked={daysMode === DAYS_MODE_ED}
                                  onChange={() => {
                                    setMonthDays([]);
                                    setWeekDays([1, 2, 3, 4, 5, 6, 7]);
                                    setDaysMode(DAYS_MODE_ED);
                                    setErrors({});
                                  }}
                                />
                              </RadioGroup>

                              {daysMode === DAYS_MODE_DOW && (
                                <>
                                  <div>
                                    {WEEK_DAYS.map((day, idx) => (
                                      <FormControlLabel
                                        key={day}
                                        control={
                                          <Checkbox
                                            onChange={() => onChangeWeekDay(idx + 1)}
                                            checked={weekDays.includes(idx + 1)}
                                          />
                                        }
                                        label={day}
                                      />
                                    ))}
                                  </div>
                                  <div>
                                    {errors.week_days && (
                                      <Typography color="error" variant="caption">
                                        {errors.week_days}
                                      </Typography>
                                    )}
                                  </div>
                                </>
                              )}

                              {daysMode === DAYS_MODE_DOM && (
                                <>
                                  <div className={classes.domList}>
                                    {MONTH_DAYS.map(({ value, label }) => (
                                      <div key={value} className={classes.domItem}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              onChange={() => onChangeMonthDay(value)}
                                              checked={monthDays.includes(value)}
                                            />
                                          }
                                          label={label}
                                        />
                                      </div>
                                    ))}
                                  </div>
                                  <div>
                                    {errors.month_days && (
                                      <Typography color="error" variant="caption">
                                        {errors.month_days}
                                      </Typography>
                                    )}
                                  </div>
                                </>
                              )}
                            </Grid>
                          )}
                        </>
                      )}
                      {campaignType === 'onetime' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <DateTimePicker
                              label="Offer valid from"
                              source="valid_from"
                              inputVariant="filled"
                              fullWidth
                              margin="dense"
                              required
                              ampm={false}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <DateTimePicker
                              label="Offer valid to"
                              source="valid_to"
                              inputVariant="filled"
                              fullWidth
                              margin="dense"
                              required
                              ampm={false}
                              externalError={errors?.valid_to}
                              removeError={removeError}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="discount_category"
                          label="Category of discount"
                          choices={DISCOUNT_CATEGORY_CHOICES}
                          validate={[required('Please fill in obligatory field')]}
                          fullWidth
                          onChange={e => {
                            setDiscountCategorySelected(e.target.value);
                            setDiscountTypeSelected('');
                            handleUnattachFile(attachments[0]?.id);
                            formProps.form.change('discount_type', null);
                            formProps.form.change('discount_total_amount', null);
                            formProps.form.change('discount_total_percent', null);
                            formProps.form.change('discount_principal_percent', null);
                            formProps.form.change('discount_interest_percent', null);
                            formProps.form.change('discount_commission_percent', null);
                            formProps.form.change('discount_past_due_interest_percent', null);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SelectInput
                          source="discount_type"
                          label="Type of discounts"
                          choices={
                            discountCategorySelected === 'loan_extension'
                              ? DISCOUNT_EXTENSION_TYPE_CHOICES
                              : DISCOUNT_TYPE_CHOICES
                          }
                          validate={[required('Please fill in obligatory field')]}
                          fullWidth
                          onChange={e => {
                            setDiscountTypeSelected(e.target.value);
                            formProps.form.change('discount_total_amount', null);
                            formProps.form.change('discount_total_percent', null);
                            formProps.form.change('discount_principal_percent', null);
                            formProps.form.change('discount_interest_percent', null);
                            formProps.form.change('discount_commission_percent', null);
                            formProps.form.change('discount_past_due_interest_percent', null);
                          }}
                        />
                      </Grid>
                      {discountTypeSelected === 'amount' && (
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount MXN"
                            source="discount_total_amount"
                            fullWidth
                            min={1}
                            step={1}
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(1),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                      )}
                      {discountTypeSelected === 'total_percent' && (
                        <Grid item xs={12} sm={6}>
                          <NumberInput
                            label="Discount %"
                            source="discount_total_percent"
                            min={1}
                            max={99}
                            step={1}
                            fullWidth
                            validate={[
                              required('Please fill in obligatory field'),
                              minValue(1),
                              maxValue(99),
                              integerValidator('Incorrect discount'),
                              number('Incorrect discount'),
                            ]}
                          />
                        </Grid>
                      )}
                      {discountTypeSelected === 'compound_percent' && (
                        <Grid container spacing={2}>
                          {discountCategorySelected !== 'loan_extension' && (
                            <Grid item xs={12} sm={6}>
                              <NumberInput
                                label="Discount for Principal %"
                                source="discount_principal_percent"
                                fullWidth
                                min={0}
                                max={100}
                                step={1}
                                validate={[
                                  required('Please fill in obligatory field'),
                                  minValue(0),
                                  maxValue(100),
                                  integerValidator('Incorrect discount'),
                                  number('Incorrect discount'),
                                ]}
                              />
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for Interest  %"
                              source="discount_interest_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for commission %"
                              source="discount_commission_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <NumberInput
                              label="Discount for Past due interest %"
                              source="discount_past_due_interest_percent"
                              fullWidth
                              min={0}
                              max={100}
                              step={1}
                              validate={[
                                required('Please fill in obligatory field'),
                                minValue(0),
                                maxValue(100),
                                integerValidator('Incorrect discount'),
                                number('Incorrect discount'),
                              ]}
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    {discountCategorySelected !== 'loan_extension' && (
                      <>
                        <Divider className={classes.my2} />
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <SelectInput
                              source="max_payments"
                              label="Number of payments"
                              allowEmpty
                              emptyValue={0}
                              emptyText="Not selected"
                              optionText="name"
                              choices={PAYMENT_LIMIT_CHOICES}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      {campaignType === 'onetime' && (
                        <Grid item xs={12} sm={6}>
                          <SelectInput
                            source="audience_type"
                            label="Loans"
                            allowEmpty
                            emptyValue={1}
                            emptyText="Not selected"
                            choices={AUDIENCE_TYPE_CHOICES}
                            onChange={e => {
                              setAudienceTypeSelected(e.target.value);
                            }}
                            validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                            fullWidth
                          />
                        </Grid>
                      )}
                      {(campaignType === 'regular' || campaignType === 'manual') && (
                        <Grid item xs={12} sm={6}>
                          <SelectInput
                            source="audience_type"
                            label="Loans"
                            allowEmpty
                            emptyValue={1}
                            emptyText="Not selected"
                            choices={AUDIENCE_TYPE_CHOICES}
                            validate={[required('Please fill in obligatory field'), value => selectedValidator(value)]}
                            disabled
                            fullWidth
                          />
                        </Grid>
                      )}
                      {((campaignType === 'onetime' && audienceTypeSelected === 'audience') ||
                        campaignType === 'regular' ||
                        campaignType === 'manual') && (
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            resource="mass_sending_audiences"
                            label="Choose audience"
                            source="audience_id"
                            optionValueProp="id"
                            optionLabelProp="name"
                            required
                          />
                        </Grid>
                      )}
                      {campaignType === 'onetime' && audienceTypeSelected === 'file' && (
                        <Grid item xs={12} sm={6}>
                          <>
                            {files.length === 0 ? (
                              <Box className={classes.mt2}>
                                {discountCategorySelected ? (
                                  <label htmlFor="upload_file">
                                    <input
                                      className={classes.hidden}
                                      type="file"
                                      id="upload_file"
                                      accept="text/csv"
                                      onChange={handleAttachFile}
                                      onClick={e => {
                                        e.target.value = null;
                                      }}
                                    />
                                    <Button
                                      color="primary"
                                      aria-label="upload file"
                                      className={classes.uploadIcon}
                                      startIcon={loadingFile ? <CircularProgress size={20} /> : <AttachFileIcon />}
                                      component="span">
                                      Attach file
                                    </Button>
                                  </label>
                                ) : (
                                  <Typography color="textSecondary" variant="caption">
                                    Select category of discount first
                                  </Typography>
                                )}
                              </Box>
                            ) : (
                              <Box className={classes.mt3}>
                                {attachments.map(file => (
                                  <Box key={file.src} className={classes.flex}>
                                    <Box className={classes.flex}>
                                      <AttachmentIcon color="primary" fontSize="small" />
                                      {file.file_name}
                                    </Box>
                                    <IconButton
                                      color="primary"
                                      aria-label="unattach file"
                                      className={classes.removeIcon}
                                      component="span"
                                      edge="start"
                                      onClick={() => handleUnattachFile(file.id)}>
                                      <CloseIcon color="primary" fontSize="small" />
                                    </IconButton>
                                  </Box>
                                ))}
                              </Box>
                            )}
                            <CheckFileResultsDialog
                              excludedLoansList={excludedLoans}
                              nonexistentLoansList={nonexistentLoans}
                              isOpened={isCheckFileResultsDialogOpen}
                              onApprove={() => {
                                setIsCheckFileResultsDialogOpen(false);
                                setExcludedLoans([]);
                                setNonexistentLoans([]);
                                setFiles([]);
                              }}
                              onReject={() => {
                                setIsCheckFileResultsDialogOpen(false);
                                setExcludedLoans([]);
                                setNonexistentLoans([]);
                              }}
                            />
                          </>
                          <div>
                            {errors.file && (
                              <Typography color="error" variant="caption">
                                {errors.file}
                              </Typography>
                            )}
                          </div>
                        </Grid>
                      )}
                    </Grid>
                    <Divider className={classes.my2} />
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <BooleanInput label="Active" disabled helperText={false} source="is_enabled" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <BooleanInput label="Display in private cabinet" helperText={false} source="show_to_client" />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <BooleanInput
                          label="Send notification"
                          helperText={false}
                          source="send_notification"
                          onChange={value => {
                            setSendNotification(value);
                            !value && formProps.form.change('discount_campaign_notifications', undefined);
                          }}
                        />
                      </Grid>
                    </Grid>
                    {sendNotification && (
                      <>
                        <Divider className={classes.my2} />
                        <ArrayInput
                          source="discount_campaign_notifications"
                          label="Notifications settings"
                          validate={[
                            required('Please add at least one row of fields'),
                            fields => notificationsValidator(fields),
                          ]}>
                          <SimpleFormIterator disableReordering>
                            <SelectInput
                              onChange={e => {
                                const value = e.target.value;
                                const fieldNamePrefix = e.target.name.split('.')[0];
                                formProps.form.change(`${fieldNamePrefix}.template_id`, null);
                                formProps.form.resetFieldState(`${fieldNamePrefix}.template_id`);
                                if (value === 'mailer') {
                                  setTemplateFilter(prev => ({ ...prev, category: 'Email' }));
                                } else {
                                  setTemplateFilter(prev => ({ ...prev, category: 'SMS' }));
                                }
                              }}
                              source="transmitter_id"
                              optionText="name"
                              label="Transmitter"
                              choices={relevantTransmitters}
                              validate={[required('Please fill in obligatory field')]}
                              fullWidth
                              helperText={false}
                            />
                            <Autocomplete
                              resource="templates"
                              source="template_id"
                              label="Template"
                              optionValueProp="id"
                              optionLabelProp="key"
                              filter={templateFilter}
                              required
                              requiredMessage="Please fill in obligatory field"
                            />
                            <TextInput
                              helperText="Format: hh:mm"
                              label="Sending time from"
                              source="notify_from"
                              validate={[
                                required('Please fill in obligatory field'),
                                value => shortTimeValidator(value),
                              ]}
                              fullWidth
                            />
                            <TextInput
                              helperText="Format: hh:mm"
                              label="Sending time till"
                              source="notify_to"
                              validate={[
                                required('Please fill in obligatory field'),
                                value => shortTimeValidator(value),
                              ]}
                              fullWidth
                            />
                          </SimpleFormIterator>
                        </ArrayInput>
                      </>
                    )}
                  </Box>
                  <Divider className={classes.my2} />
                  <Box display="flex" justifyContent="flex-end" sx={{ gap: '1rem' }}>
                    <ListButton icon={<ArrowBackIcon />} size="medium" label="Back to list" variant="outlined" />
                    <SaveButton
                      label="Submit"
                      redirect="show"
                      saving={formProps.saving}
                      handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
  campaignType: PropTypes.string,
};

const DiscountCampaignCreate = props => {
  const [campaignType, setCampaignType] = useState(null);
  const classes = useStyles();
  return (
    <>
      {!campaignType && (
        <Grid container justifyContent="center" spacing={4}>
          <Grid item xs={12} sm={10}>
            <Paper className={classes.paper}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom={false}>
                    Select campaign type
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Select
                    onChange={e => {
                      setCampaignType(e.target.value);
                    }}
                    variant="filled"
                    fullWidth
                    value={campaignType}>
                    {CAMPAIGN_TYPE_CHOICES.map(item => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
      {campaignType && (
        <Create component="div" {...props}>
          <FormWrapper campaignType={campaignType} />
        </Create>
      )}
    </>
  );
};
export default DiscountCampaignCreate;
