import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Chip from '@material-ui/core/Chip';
import Link from '@material-ui/core/Link';
import { formatCurrency, formatDatetime } from '../../utils';

const useStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  fullWidth: {
    width: '100%',
  },
});

export const CollapsableRow = ({ row }) => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell>
          <Link onClick={e => e.stopPropagation()} href={`#/loans/${row.loan_id}/show`}>
            {`#${row.loan_id}`}
          </Link>
        </TableCell>
        <TableCell>{row.campaign_id}</TableCell>
        <TableCell>{row.strategy_id}</TableCell>
        <TableCell>{row.admin_id}</TableCell>
        <TableCell>
          <Chip size="small" label={row.state} />
        </TableCell>
        <TableCell>{formatCurrency(row.amount)}</TableCell>
        <TableCell>{formatDatetime(row.confirmed_at)}</TableCell>
        <TableCell>{formatDatetime(row.created_at)}</TableCell>
        <TableCell>{row.dpd}</TableCell>
        <TableCell>
          <Typography variant="caption">{row.error}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Requests
              </Typography>
              {row.requests?.length > 0 ? (
                <Table size="small" aria-label="requests">
                  <TableHead>
                    <TableRow>
                      <TableCell>Request ID</TableCell>
                      <TableCell>Income ID</TableCell>
                      <TableCell>State</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Error</TableCell>
                      <TableCell>Confirmed at</TableCell>
                      <TableCell>Charge back at</TableCell>
                      <TableCell>Created at</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.requests?.map(request => (
                      <TableRow key={request.id}>
                        <TableCell component="th" scope="row">
                          {request.id}
                        </TableCell>
                        <TableCell>{request.income_id}</TableCell>
                        <TableCell>
                          <Chip size="small" label={request.state} />
                        </TableCell>
                        <TableCell>{formatCurrency(request.amount)}</TableCell>
                        <TableCell>{request.error}</TableCell>
                        <TableCell>{formatDatetime(request.confirmed_at)}</TableCell>
                        <TableCell>{formatDatetime(request.charge_back_at)}</TableCell>
                        <TableCell>{formatDatetime(request.created_at)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Typography variant="body2" align="center" gutterBottom>
                  No requests found
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

CollapsableRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    loan_id: PropTypes.number,
    campaign_id: PropTypes.number,
    strategy_id: PropTypes.number,
    admin_id: PropTypes.number,
    state: PropTypes.string,
    amount: PropTypes.number,
    confirmed_at: PropTypes.string,
    created_at: PropTypes.string,
    dpd: PropTypes.number,
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        income_id: PropTypes.number,
        state: PropTypes.string,
        amount: PropTypes.number,
        error: PropTypes.string,
        confirmed_at: PropTypes.string,
        created_at: PropTypes.string,
      }),
    ),
    error: PropTypes.string,
  }),
};
