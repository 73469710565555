import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Create,
  SelectInput,
  SaveButton,
  FormWithRedirect,
  FormDataConsumer,
  useNotify,
  useDataProvider,
} from 'react-admin';
import { FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
import ImportProcessLoanClose from './helper/ImportProcessLoanClose';
import ImportProcessHelperBlackList from './helper/ImportProcessHelperBlackList';
import ImportProcessHelperCollectorAssign from './helper/ImportProcessHelperCollectorAssign';
import ImportProcessHelperCollectorCompany from './helper/ImportProcessHelperCollectorCompany';
import ImportProcessHelperNotification from './helper/ImportProcessHelperNotification';
import ImportProcessHelperSold from './helper/ImportProcessHelperSold';
import ImportProcessHelperUserTags from './helper/ImportProcessHelperUserTags';
import ImportProcessHelperSoldAmount from './helper/ImportProcessHelperSoldAmount';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  input: {
    display: 'none',
  },
  helperWrapper: {
    paddingTop: 16,
  },
}));

const ParamsInput = ({ importerId }) => {
  switch (importerId) {
    default:
      return null;
  }
};

ParamsInput.propTypes = {
  importerId: PropTypes.string,
};

const FileInput = ({ selectedFile, onFileChange }) => {
  const classes = useStyles();

  return (
    <label htmlFor="upload-button">
      <IconButton color="primary" aria-label="upload file" component="span">
        <CloudUploadIcon />
      </IconButton>
      <Typography variant="caption" display="inline" gutterBottom>
        {selectedFile ? `${selectedFile.name} (${selectedFile.size} bytes)` : 'Upload file'}
      </Typography>
      <input
        id="upload-button"
        className={classes.input}
        accept="text/csv"
        type="file"
        onChange={e => onFileChange(e.target.files[0])}
      />
    </label>
  );
};

FileInput.propTypes = {
  selectedFile: PropTypes.object,
  onFileChange: PropTypes.func,
};

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [importers, setImporters] = useState([]);
  const [transmiters, setTransmiters] = useState([]);
  const [isProcessingFile, setIsProcessingFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);

  const onFileChange = file => {
    setIsProcessingFile(true);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setIsProcessingFile(false);
      setSelectedFile(file);
      setFileContent(reader.result);
    });
    reader.readAsText(file);
  };

  useEffect(() => {
    dataProvider
      .query('import_processes/importer_list', { method: 'GET' })
      .then(({ data }) => {
        setImporters(data.filter(i => i.id !== 'user'));
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  useEffect(() => {
    dataProvider
      .query('notifications/transmitters', { method: 'GET' })
      .then(({ data }) => {
        setTransmiters(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading === false && error) {
    notify(`Error: ${error.message}`, 'error');
  }

  return (
    <Grid container justifyContent="center" spacing={4}>
      <Grid item xs={12} sm={6}>
        <Paper className={classes.paper}>
          <Typography variant="h6" gutterBottom={false}>
            Create import process
          </Typography>
          <Divider />
          <FormWithRedirect
            save={({ importer_id, transmitter_id, ...params }, ...rest) => {
              save(
                ...[
                  {
                    importer_id,
                    data: fileContent,
                    params: {
                      ...params[importer_id],
                      transmitter_id,
                    },
                  },
                  ...rest,
                ],
              );
            }}
            {...props}
            render={formProps => (
              <>
                <SelectInput
                  helperText={false}
                  className={classes.fullWidth}
                  onChange={() => formProps.form.change('transmitter_id', null)}
                  source="importer_id"
                  choices={importers}
                />
                <FormSpy subscription={{ values: {} }}>
                  {({ values: { importer_id: importerId } }) => {
                    return (
                      <>
                        {importerId === 'notification' ? (
                          <FormDataConsumer>
                            {({ formData, ...rest }) => (
                              <SelectInput
                                source="transmitter_id"
                                fullWidth
                                helperText={false}
                                choices={formData.importer_id === 'notification' ? transmiters : []}
                                {...rest}
                              />
                            )}
                          </FormDataConsumer>
                        ) : null}
                        <ParamsInput importerId={importerId} />
                      </>
                    );
                  }}
                </FormSpy>
                {isProcessingFile ? (
                  <CircularProgress />
                ) : (
                  <FileInput selectedFile={selectedFile} onFileChange={onFileChange} />
                )}
                <Divider className={classes.my2} />
                <SaveButton
                  label="Submit"
                  saving={formProps.saving}
                  disabled={!fileContent}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <FormSpy subscription={{ values: {} }}>
                  {({ values: { importer_id: importedId } }) => (
                    <div className={classes.helperWrapper}>
                      {importedId === 'black_list' && <ImportProcessHelperBlackList />}
                      {importedId === 'collector_assign' && <ImportProcessHelperCollectorAssign />}
                      {importedId === 'collector_company' && <ImportProcessHelperCollectorCompany />}
                      {importedId === 'notification' && <ImportProcessHelperNotification />}
                      {importedId === 'sold' && <ImportProcessHelperSold />}
                      {importedId === 'user_tags' && <ImportProcessHelperUserTags />}
                      {importedId === 'loan_close' && <ImportProcessLoanClose />}
                      {importedId === 'sold_amount' && <ImportProcessHelperSoldAmount />}
                    </div>
                  )}
                </FormSpy>
              </>
            )}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

export default props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
