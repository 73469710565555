import React from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  maxLength,
  required,
  SaveButton,
  CheckboxGroupInput,
  TextInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Paper, Typography, Box } from '@material-ui/core';
import { Autocomplete } from '../../components';
import { time as timeValidator } from '../../utils';
import { WEEK_DAYS_CHOICES } from '../../constants';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const FormWrapper = ({ save, ...props }) => {
  const classes = useStyles();

  return (
    <FormWithRedirect
      save={({ is_enabled, name, start_time, audience_id, params }, ...rest) => {
        const payload = {
          is_enabled,
          name,
          start_time,
          audience_id,
          params,
        };
        save(...[payload, ...rest]);
      }}
      {...props}
      render={formProps => {
        return (
          <form>
            <Grid container justifyContent="center" spacing={4}>
              <Grid item xs={12} sm={6}>
                <Paper className={classes.paper}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={9}>
                      <Typography variant="h6" gutterBottom={false}>
                        Create autocreating application scheduler
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <BooleanInput label="Enabled" source="is_enabled" helperText={false} defaultValue={false} />
                    </Grid>
                  </Grid>
                  <Divider className={classes.my2} />
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextInput source="name" validate={[required(), maxLength(255)]} fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <TextInput
                          helperText="Format: hh:mm:ss"
                          source="start_time"
                          validate={[required(), value => timeValidator(value)]}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          resource="mass_sending_audiences"
                          source="audience_id"
                          optionValueProp="id"
                          optionLabelProp="name"
                          required
                          withClickableOptions
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <CheckboxGroupInput
                          source="params.week_days"
                          label="Week days"
                          choices={WEEK_DAYS_CHOICES}
                          validate={required()}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Divider className={classes.my2} />
                  <SaveButton
                    label="Submit"
                    redirect="/application_autocreating_processes"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                  />
                </Paper>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};

FormWrapper.propTypes = {
  save: PropTypes.func,
};

const AutocreatingAppsSchedulerCreate = props => (
  <Create component="div" {...props}>
    <FormWrapper />
  </Create>
);
export default AutocreatingAppsSchedulerCreate;
