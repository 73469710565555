import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Filter,
  TextInput,
  NumberInput,
  TopToolbar,
  CreateButton,
  useListContext,
  sanitizeListRestProps,
  SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination } from '../../components';
import { marginZeroStyles } from '../../constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Code" source="code" />
      <TextInput label="Campaign" source="campaign" />
      <SelectInput
        label="Is enabled"
        source="is_enabled"
        choices={[
          { id: 'true', name: 'Enabled' },
          { id: 'false', name: 'Disabled' },
        ]}
      />
      <SelectInput
        label="Type"
        source="type"
        choices={[
          { id: 'manual', name: 'Manual' },
          { id: 'interest_10_time_24', name: 'Interest 10 time 24' },
          { id: 'interest_20_time_24', name: 'Interest 20 time 24' },
          { id: 'interest_30_time_24', name: 'Interest 30 time 24' },
          { id: 'interest_10_time_48', name: 'Interest 10 time 48' },
          { id: 'interest_20_time_48', name: 'Interest 20 time 48' },
          { id: 'interest_30_time_48', name: 'Interest 30 time 48' },
        ]}
      />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export default props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilter />}
    actions={<ListActions />}
    {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="code" />
      <TextField source="campaign" />
      <TextField source="type" />
      <BooleanField source="is_enabled" />
      <BooleanField source="is_reusable" />
    </Datagrid>
  </List>
);
