import React, { cloneElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  CreateButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  sanitizeListRestProps,
  TextField,
  TextInput,
  TopToolbar,
  useListContext,
  useDataProvider,
  useNotify,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Pagination, ListSkeleton } from '../../components';
import { marginZeroStyles, WEEK_DAYS } from '../../constants';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFilter = props => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <TextInput label="Name" source="name" />
      <TextInput label="Audience name" source="audience->name" />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, hasCreate, basePath, showFilter } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

export default props => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .getList('admins', {
        filter: {},
        pagination: { page: 1, perPage: 300 },
        sort: {},
      })
      .then(({ data }) => setAdmins(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider]);

  const getAdminName = id => {
    const admin = admins.find(admin => admin.id === id);
    return admin ? `${admin.username} #${admin.id}` : null;
  };

  if (loading) return <ListSkeleton />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilter />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="audience_name" />
        <TextField source="start_time" />
        <FunctionField
          label="Period"
          render={record => {
            const weekDays = Array.isArray(record?.params?.week_days) ? record.params.week_days : [];
            weekDays.sort();

            return (
              weekDays.length > 0 && (
                <>
                  <div>
                    <b>Days of week:</b>
                  </div>
                  <div>{weekDays.map(day => WEEK_DAYS[day - 1] || 'UN').join(', ')}</div>
                </>
              )
            );
          }}
        />
        <BooleanField source="is_enabled" />
        <FunctionField
          label="Created"
          render={({ created_at, created_by_id }) => (
            <Typography variant="body2">
              {formatDatetime(created_at)}
              <br />
              {created_by_id ? ` by ${getAdminName(created_by_id)}` : '---'}
            </Typography>
          )}
        />
        <FunctionField
          label="Updated"
          render={({ updated_at, updated_by_id }) => (
            <Typography variant="body2">
              {formatDatetime(updated_at)}
              <br />
              {updated_by_id ? ` by ${getAdminName(updated_by_id)}` : '---'}
            </Typography>
          )}
        />
      </Datagrid>
    </List>
  );
};
