import { format, formatInTimeZone } from 'date-fns-tz';
import { getAdminTimezone } from '../../utils';

export const checkAndSetDate = value => {
  const currentTimeZone = getAdminTimezone(false);
  const nowTimezoned = formatInTimeZone(new Date(), currentTimeZone, "yyyy-MM-dd'T'HH:mm:ssXXX");
  let validFromTimzoned = format(value, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: currentTimeZone });

  if (validFromTimzoned < nowTimezoned) {
    validFromTimzoned = nowTimezoned;
  }
  return validFromTimzoned;
};
