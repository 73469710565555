import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  Filter,
  TextInput,
  NumberInput,
  SelectInput,
  TopToolbar,
  useListContext,
  sanitizeListRestProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { marginZeroStyles, REQUEST_TYPE_CHOICES } from '../../constants';
import { Pagination, DateFilterInput, QuickFilterInput, AsyncSelectInput } from '../../components';
import { LOAN_LIST_ROWS } from './constants';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

export const ListFilters = props => {
  const classes = useStyles();
  const statefilterchoices = [
    { id: 'active', name: 'Active' },
    { id: 'closed', name: 'Closed' },
    { id: 'defaulted', name: 'Defaulted' },
    { id: 'inactive', name: 'Inactive' },
    { id: 'pending', name: 'Pending' },
    { id: 'cep_fail', name: 'CEP fail' },
    { id: 'transfer_fail', name: 'Transfer fail' },
  ];

  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <AsyncSelectInput
        label="Affiliate"
        source="application->affiliate->id"
        query={{
          resource: 'affiliates',
          payload: {
            pagination: { page: 1 },
            sort: { field: 'id', order: 'DESC' },
            filter: { page: 1, items_per_page: 100 },
          },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <TextInput label="User contacts" source="user->phoneBooks->value" />
      <SelectInput label="Status" source="state" choices={statefilterchoices} />
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <SelectInput label="Loan type" source="is_repeat" choices={REQUEST_TYPE_CHOICES} />
      <QuickFilterInput source="is_direct_debit_registered" label="Direct debit registered" defaultValue={true} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
      <QuickFilterInput source="has_failed_money_transfer" label="Has failed money transfer" defaultValue={true} />
      <QuickFilterInput source="has_active_promocode" label="Has active promocode" defaultValue={true} />
      <NumberInput label="Min extensions count" source="loan_extension->count_extensions|gte" />
      <NumberInput label="Max extensions count" source="loan_extension->count_extensions|lte" />
      <NumberInput label="Min last ext tenor" source="loan_extension->last_extension_tenor|gte" />
      <NumberInput label="Max last ext tenor" source="loan_extension->last_extension_tenor|lte" />
      <DateFilterInput label="Last ext started after" source="loan_extension->last_extension_started_at|after" after />
      <DateFilterInput
        label="Last ext started before"
        source="loan_extension->last_extension_started_at|before"
        before
      />
    </Filter>
  );
};

const ListActions = ({ className, filters, ...rest }) => {
  const { resource, displayedFilters, filterValues, showFilter } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

ListActions.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.element,
};

const LoanList = props => (
  <List
    pagination={<Pagination />}
    bulkActionButtons={false}
    sort={{ field: 'id', order: 'DESC' }}
    filters={<ListFilters />}
    filterDefaultValues={{
      _state: ['active', 'closed', 'defaulted', 'inactive', 'pending', 'cep_fail', 'transfer_fail'],
    }}
    actions={<ListActions />}
    {...props}>
    <Datagrid rowClick="show">{LOAN_LIST_ROWS.map(i => i)}</Datagrid>
  </List>
);

export default LoanList;
