import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  flexBoxSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textBtn: {
    width: '110px',
    height: '70px',
  },
  input: {
    width: '190px',
    height: '70px',
  },
  header: {
    height: '50px',
  },
}));

export const ListSkeleton = () => {
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.flexBoxSpaceBetween}>
        <Skeleton className={classes.input} />
        <Skeleton className={classes.textBtn} />
      </Box>
      <Box>
        <Skeleton className={classes.header} />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton className={classes.header} />
      </Box>
    </Box>
  );
};
