import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const SmsCount = ({ source = 'template', locale = 'locale' }) => {
  const state = useFormState();
  const OPTIONS = {
    en_LK: 160,
    si_LK: 70,
    ta_LK: 70,
  };
  return (
    <Box>
      <Typography variant="caption" display="block">
        SMS length: {state.values[source] && state.values[source].length}{' '}
      </Typography>
      <Typography variant="caption" display="block">
        Messages count: {Math.ceil(state.values[source] && state.values[source].length / OPTIONS[state.values[locale]])}
      </Typography>
    </Box>
  );
};

SmsCount.propTypes = {
  source: PropTypes.string,
  locale: PropTypes.string,
};
