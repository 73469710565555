import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useProducts } from '../../../hooks';

const ConfirmTransitionDialog = ({ isOpened, onClose, onSubmit, record }) => {
  const { min_approved_tenor, max_approved_tenor, min_approved_principal, max_approved_principal, product_id } = record;

  const [confirmationCode, setConfirmationCode] = useState();
  const [displayedPrincipal, setDisplayedPrincipal] = useState(max_approved_principal);
  const [displayedTenor, setDisplayedTenor] = useState(max_approved_tenor);
  const [loading, setLoading] = useState(false);

  const { data: products } = useProducts();
  const product = products.find(product => product_id === product.id);

  const handleSubmit = () => {
    setLoading(true);
    onSubmit(confirmationCode, displayedPrincipal, displayedTenor);
  };

  return (
    <Dialog open={isOpened} onClose={onClose} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">Confirm application</DialogTitle>
      <DialogContent>
        <DialogContentText>Please, enter confirmation code, principal and tenor</DialogContentText>
        <Typography id="approved-principal-slider" gutterBottom>
          Confirmed principal, $: {`${displayedPrincipal}`}
        </Typography>
        <Slider
          defaultValue={max_approved_principal}
          valueLabelFormat={value => `${value / 1e3}k`}
          aria-labelledby="approved-principal-slider"
          valueLabelDisplay="auto"
          step={product?.principal.step || 1000}
          marks
          min={min_approved_principal}
          max={max_approved_principal}
          onChangeCommitted={(ev, value) => setDisplayedPrincipal(value)}
          onChange={(ev, value) => setDisplayedPrincipal(value)}
        />
        <Typography id="approved-tenor-slider" gutterBottom>
          Confirmed tenor, days: {displayedTenor}
        </Typography>
        <Slider
          defaultValue={max_approved_tenor}
          aria-labelledby="approved-tenor-slider"
          valueLabelDisplay="auto"
          step={product?.tenor.step || 5}
          marks
          min={min_approved_tenor}
          max={max_approved_tenor}
          onChangeCommitted={(ev, value) => setDisplayedTenor(value)}
          onChange={(ev, value) => setDisplayedTenor(value)}
        />
        <TextField
          id="confirmation-code-field"
          label="Code"
          onChange={e => setConfirmationCode(e.target.value)}
          autoFocus={true}
          fullWidth={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          startIcon={loading ? <CircularProgress size={20} thickness={1.5} /> : null}
          onClick={handleSubmit}
          color="primary"
          disabled={!confirmationCode}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmTransitionDialog.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  record: PropTypes.shape({
    product_id: PropTypes.number,
    min_approved_tenor: PropTypes.number,
    max_approved_tenor: PropTypes.number,
    min_approved_principal: PropTypes.number,
    max_approved_principal: PropTypes.string,
  }),
};

export default ConfirmTransitionDialog;
