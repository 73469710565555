import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ChipField,
  FunctionField,
  Filter,
  TextInput,
  SelectInput,
  NumberInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ListActions from './components/ListActions';
import { Pagination, AsyncSelectInput, DateFilterInput, QuickFilterInput, ListFilterHOC } from '../../components';
import { marginZeroStyles, REQUEST_TYPE_CHOICES } from '../../constants';
import { formatDatetime } from '../../utils';

const useStyles = makeStyles(() => ({
  ...marginZeroStyles,
}));

const ListFiltersExternalAgency = ({ statefilterchoices, ...props }) => {
  const classes = useStyles();
  return (
    <Filter {...props} classes={{ form: classes.marginZero }}>
      <NumberInput label="Id" source="id" alwaysOn />
      <NumberInput label="User Id" source="user->id" />
      <TextInput label="User name" source="search_by_name" />
      <TextInput label="Contract number" source="application->contractNumber" />
      <TextInput label="Document number" source="user->userDocuments->number" />
      <AsyncSelectInput
        label="External agency"
        source="external_agency->id"
        query={{
          resource: 'external_agencies',
          payload: { pagination: { page: 1 }, sort: { field: 'id', order: 'DESC' }, filter: {} },
        }}
        mapper={({ id, name }) => ({ value: id, name })}
      />
      <TextInput label="User contacts" source="user->phoneBooks->value" />
      {statefilterchoices.length ? <SelectInput label="Status" source="state" choices={statefilterchoices} /> : null}
      <NumberInput label="Min principal" source="principal|gte" />
      <NumberInput label="Max principal" source="principal|lte" />
      <NumberInput label="Min tenor" source="tenor|gte" />
      <NumberInput label="Max tenor" source="tenor|lte" />
      <DateFilterInput label="Created before" source="created_at|before" before />
      <DateFilterInput label="Created after" source="created_at|after" after />
      <DateFilterInput label="Disbursed before" source="disbursed_at|before" before />
      <DateFilterInput label="Disbursed after" source="disbursed_at|after" after />
      <DateFilterInput label="Matured before" source="matured_at|before" before />
      <DateFilterInput label="Matured after" source="matured_at|after" after />
      <QuickFilterInput source="is_extension_requested" label="Extension requested" defaultValue={true} />
      <QuickFilterInput source="is_extension_possible" label="Extension possible" defaultValue={true} />
      <QuickFilterInput source="is_extended" label="Extended" defaultValue={true} />
      <QuickFilterInput source="has_charge_back" label="Has charge back" defaultValue={true} />
      <SelectInput label="Loan type" source="is_repeat" choices={REQUEST_TYPE_CHOICES} />
      <TextInput label="Residence municipality" source="user->residence->municipality" />
      <NumberInput label="Max days past due" source="days_past_due|lte" />
      <NumberInput label="Min days past due" source="days_past_due|gte" />
    </Filter>
  );
};

ListFiltersExternalAgency.propTypes = {
  statefilterchoices: PropTypes.array,
};

const ListExternalAgency = ({ statefilterchoices = [], listfilters: ListFilters, ...props }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [agenciesList, setAgenciesList] = React.useState([]);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    dataProvider
      .query('external_agencies?page=1&items_per_page=200', { method: 'GET' })
      .then(({ data }) => setAgenciesList(data))
      .catch(error => setError(error))
      .finally(() => setLoading(false));
  }, [dataProvider, notify]);

  if (loading) return <CircularProgress />;
  if (error) {
    notify(`Error: ${error.message}`, 'error');
    return null;
  }

  return (
    <List
      pagination={<Pagination />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ListFilters statefilterchoices={statefilterchoices} />}
      actions={<ListActions />}
      {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" />
        <FunctionField
          sortBy="days_past_due"
          label="DPD"
          render={record => {
            return (
              <Chip
                avatar={
                  <Avatar>
                    {record.collection_group
                      ? record.collection_group.replace(' New', '').replace(' Repeat', '')
                      : 'Er'}
                  </Avatar>
                }
                label={record.days_past_due}
              />
            );
          }}
        />
        <FunctionField
          label="Assigned DPD"
          render={({ external_agency_assigned_dpd }) => <Chip label={external_agency_assigned_dpd} />}
        />
        <FunctionField
          label="Name"
          render={({ user_id, user_first_name = '', user_last_name = '' }) => (
            <Link onClick={e => e.stopPropagation()} href={'#users/' + user_id}>
              {`${user_first_name} ${user_last_name} #${user_id}`}
            </Link>
          )}
        />
        <FunctionField
          label="External agency"
          render={({ external_agency_id }) =>
            agenciesList.find(agency => agency.id === external_agency_id)?.name ?? external_agency_id
          }
        />
        <NumberField source="principal" options={{ style: 'currency', currency: 'MXN' }} />,
        <NumberField source="tenor" />
        <NumberField source="accrued_total" label="Total debt" options={{ style: 'currency', currency: 'MXN' }} />
        <ChipField key="state" size="small" source="state" sortable={false} />
        <FunctionField
          label="Loan type"
          source="is_repeat"
          sortable={false}
          render={(record, key) => <Chip size="small" label={record[key] ? 'Repeated' : 'New'} />}
        />
        <FunctionField
          label="Start"
          render={({ start_of_assigned_external_agency }) => formatDatetime(start_of_assigned_external_agency)}
        />
        <FunctionField
          label="Days of assigned"
          render={({ days_of_assigned_external_agency }) => <Chip label={days_of_assigned_external_agency} />}
        />
        <FunctionField label="Last contact date" render={({ last_call_date }) => formatDatetime(last_call_date)} />
        <FunctionField
          label="Last payment date"
          render={({ last_payment_date }) => formatDatetime(last_payment_date)}
        />
        <FunctionField
          render={record => (
            <Link href={`#loans/${record.id}/show`} underline="none" target="_blank" rel="noreferrer">
              <IconButton onClick={e => e.stopPropagation()}>
                <OpenInNewIcon fontSize="small" />
              </IconButton>
            </Link>
          )}
        />
      </Datagrid>
    </List>
  );
};

ListExternalAgency.propTypes = {
  statefilterchoices: PropTypes.array,
  listfilters: PropTypes.func,
};

const LoanListExternalAgency = ListFilterHOC(
  ListExternalAgency,
  {
    _state: ['active', 'defaulted'],
    external_agency_has: true,
  },
  [
    { id: 'active', name: 'Active' },
    { id: 'defaulted', name: 'Defaulted' },
  ],
  ListFiltersExternalAgency,
);

export default LoanListExternalAgency;
